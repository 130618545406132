.display_none {
    display: none;
}

.SearchRadio {
    margin: 0 0 30px 0
}

.SearchRadio ul li {
    display: inline-block;
    width: 140px
}

.Radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 !important;
    color: #000;
    font-weight: 500;
    font-family: 'Switzer-Medium';
    letter-spacing: .4px
}

.Radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.Radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #000
}

.Radio input:checked~.checkmark {
    border-color: #EC1E32;
    background-color: #fff
}

.Radio .checkmark:after {
    content: "";
    position: absolute;
    display: none
}

.Radio input:checked~.checkmark:after {
    display: block
}

.Radio .checkmark:after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #EC1E32;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

.ModalBox .modal-dialog {
    max-width: 600px;
}

.ModalBox .modal-dialog .modal-content {
    background: transparent;
    border-radius: 0;
    border: none;
}

.TransferBox {
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff
}

.TransferBox .TransferHeader {
    background-color: #021931;
    padding: 30px 30px;
    display: flex;
    justify-content: space-between
}

.TransferBox .TransferHeader h4 {
    font-family: 'Switzer-Regular';
    color: #fff;
    font-size: 20px
}

.TransferBox .TransferHeader h5 {
    color: #fff;
    font-size: 18px;
    font-family: 'Switzer-Regular'
}

.TransferBox .TransferHeader h5 span {
    display: inline-block;
    width: 30px;
    margin: 0 10px 0 0
}

.TransferBox .TransferHeader h5 strong {
    font-weight: 500;
    font-family: 'Switzer-Medium'
}

.TransferBox .TransferHeader button {
    box-shadow: none;
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 30px;
    line-height: 20px;
    width: 25px;
    height: 25px;
    outline: 0
}

.TransferBox .TransferBody {
    padding: 35px 35px 25px
}

.TransferBox .TransferBody h5 {
    font-size: 17px;
    margin: 0 0 24px 0;
    font-family: 'Switzer-Bold';
    letter-spacing: .4px;
    color: #000
}

.TransferBox .TransferBody .form-group {
    margin: 0 0 25px;
    position: relative
}

.TransferBox .TransferBody .form-group:last-child {
    margin: 0
}

.TransferBox .TransferBody .form-group label {
    display: inline-block;
    text-transform: capitalize;
    font-weight: 500;
    color: #000;
    font-size: 14px;
    font-family: 'Switzer-Medium';
    background-color: #fff;
    margin: 0;
    padding: 6px 10px;
    position: absolute;
    top: -18px;
    left: 10px;
    border-radius: 5px;
    z-index: 1;
    letter-spacing: .4px
}

.TransferBox .TransferBody .form-group label.CheckBox {
    position: relative;
    top: auto;
    left: auto;
    padding: 0 0 0 35px;
    font-weight: 500;
    margin: -20px 0 0 0 !important;
    display: block
}

.TransferBox .TransferBody .form-group .form-control {
    box-shadow: none;
    border: 1px solid #c3c3c3;
    height: auto;
    font-size: 14px;
    color: #000;
    line-height: 22px;
    padding: 20px 15px 15px;
    font-family: 'Switzer-Regular';
    letter-spacing: .5px
}

.TransferBox .TransferBody .form-group textarea.form-control {
    height: auto
}


.TransferBox .TransferBody .SuccessfulInfo {
    text-align: center;
    padding: 20px 0;
}

.TransferBox .TransferBody .SuccessfulInfo h3 {
    font-family: 'Switzer-Medium';
    font-size: 24px;
    margin: 0 0 15px 0;
}

.TransferBox .TransferBody .SuccessfulInfo p {
    font-size: 16px;
    font-family: 'Switzer-Regular';
    letter-spacing: 0.5px;
}

.TransferBox .TransferBody .SuccessfulInfo p span {
    font-family: 'Switzer-Medium';
}

.TransferBox .TransferBody .SuccessfulInfo button {
    width: 100%;
    background-color: #f3042c;
    border: none;
    color: #fff;
    padding: 15px 0;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: .5px;
    font-family: 'Switzer-Regular';
    outline: 0;
    margin: 15px 0 0 0;
}

.TransferBox .TransferBody .SuccessfulInfo button.Yes {
    border: 1px solid #ddd;
    background-color: #fff;
    color: #000;
    font-family: 'Switzer-Medium';
}

.TransferBox .TransferBody .SuccessfulInfo a {
    width: 100%;
    background-color: #f3042c;
    border: none;
    display: inline-block;
    color: #fff;
    padding: 15px 0;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: .5px;
    font-family: 'Switzer-Regular';
    outline: 0;
    margin: 15px 0 0 0;
}

.TransferBox .TransferBody .SuccessfulInfo a.Yes {
    border: 1px solid #ddd;
    background-color: #fff;
    color: #000;
    font-family: 'Switzer-Medium';
}

.TransferBox .TransferFooter button {
    width: 100%;
    background-color: #f3042c;
    border: none;
    color: #fff;
    padding: 15px 0;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: .5px;
    font-family: 'Switzer-Regular';
    outline: 0
}

.TransferBox .TransferFooter {
    background-color: #EEEDED;
    padding: 30px
}

.TransferBox .TransferFooter a {
    width: 100%;
    background-color: #f3042c;
    border: none;
    color: #fff;
    padding: 15px 0;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: .5px;
    font-family: 'Switzer-Regular';
    outline: 0
}

.TransferBox .TransferFooter a:hover {
    color: #fff;
}

.TransferBox .TransferFooter h6 {
    font-size: 13px;
    text-align: center;
    line-height: 23px;
    color: rgba(17, 34, 17, 0.75);
    margin: 15px 0 15px 0
}

.TransferBox .TransferFooter h5 {
    font-size: 18px;
    font-family: 'Switzer-Bold';
    margin: 0 0 10px 0
}

.TransferBox .TransferFooter ul {
    padding: 0 0 0 15px;
    margin: 0 0 15px 0
}

.TransferBox .TransferFooter ul li {
    list-style: disc;
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 5px 0;
    font-family: 'Switzer-Regular'
}

.TransferBox .TransferFooter p {
    line-height: 25px;
    margin: 0 0 15px 0
}

.TransferBox .TransferBody .SearchInfo h6 {
    margin: 0 0 25px 0;
    font-size: 15px;
    line-height: 25px
}

.TransferBox .TransferBody .SearchInfo h6 strong {
    color: #667085;
    font-weight: 500
}

.TransferBox .TransferBody .SearchInfo h6 span {
    display: block;
    color: #032A52;
    letter-spacing: .4px
}

.TransferBox .TransferBody .SearchInfo p {
    color: #667085;
    font-family: 'Switzer-Regular';
    font-size: 14px;
    margin: 0 0 15px 0;
}

.TransferBox .TransferBody .SearchInfo p span {
    display: inline-block;
    width: 20px;
    margin: 0 20px 0 0
}

.TravelArea {
    padding: 25px;
    background-color: #FAFBFC
}

.TravelArea .TravelBody {
    background: linear-gradient(89.96deg, rgb(0 158 16 / 10%) 10.36%, rgb(236 30 50 / 10%) 112.25%);
    margin: 0 0 20px 0;
    padding: 20px 20px 20px 80px;
    border-radius: 8px;
    position: relative
}

.TravelArea .TravelBody .Icon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 50px
}

.TravelArea .TravelBody h6 {
    color: #009E10;
    font-size: 20px;
    font-weight: 600;
    font-family: Montserrat;
    margin: 0 0 5px 0
}

.TravelArea .TravelBody p {
    color: #000;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px
}

.TravelArea .TravelBody button {
    background-color: #fff;
    border: 2px solid #EC1E32;
    outline: 0;
    width: 140px;
    color: #EC1E32;
    padding: 9px 0;
    border-radius: 6px;
    font-size: 14px;
    float: right
}

.TravelArea .TravelFooter {
    padding: 0
}

.TravelBox {
    background-color: #fff;
    box-shadow: 0 4px 30px 0 #00000014;
    border-radius: 15px;
    overflow: hidden;
    padding: 20px;
    display: flex
}

.TravelBox+.TravelBox {
    margin: 20px 0 0 0
}

.TravelBox .TravelLeft {
    width: 21%;
    border-radius: 10px;
    overflow: hidden
}

.TravelBox .TravelRight {
    width: 78%;
    padding: 0 0 0 20px
}

.TravelBox .TravelRight article {
    display: flex
}

.TravelBox .TravelRight article aside {
    width: 50%
}

.TravelBox .TravelRight article aside h3 {
    font-size: 22px;
    font-family: 'Switzer-Bold';
    margin: 0 0 10px 0
}

.TravelBox .TravelRight article aside h3 span {
    color: #667085;
    font-size: 16px;
    font-family: 'Switzer-Regular'
}

.TravelBox .TravelRight article aside p {
    margin: 0 0 10px 0
}

.TravelBox .TravelRight article aside p span {
    background-color: #EAF0FF;
    padding: 10px;
    border-radius: 5px;
    display: inline-block
}

.TravelBox .TravelRight article aside p span img {
    margin: 0 5px 0 0
}

.TravelBox .TravelRight article aside ol li {
    padding: 0 0 0 27px;
    margin: 0 0 15px 0;
    letter-spacing: .5px;
    font-size: 14px;
    position: relative
}

.TravelBox .TravelRight article aside ol li .Icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px
}

.TravelBox .TravelRight article aside ol li span {
    color: #667085
}

.TravelBox .TravelRight article aside ul li {
    padding: 0 0 0 35px;
    position: relative;
    margin: 0 0 10px 0;
    color: #667085;
    line-height: 26px;
    font-family: 'Switzer-Regular';
    letter-spacing: .4px
}

.TravelBox .TravelRight article aside ul li span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px
}

.TravelBox .TravelRight figcaption {
    border-top: 1px solid #ddd;
    padding: 15px 0 0 0;
    display: flex;
    justify-content: space-between
}

.TravelBox .TravelRight figcaption button {
    background-color: #fff;
    border: 2px solid #EC1E32;
    outline: 0;
    width: 140px;
    color: #EC1E32;
    padding: 9px 0;
    border-radius: 6px;
    font-size: 14px
}

.TravelBox .TravelRight figcaption aside {
    display: flex;
    align-items: center
}

.TravelBox .TravelRight figcaption aside h4 {
    font-size: 20px;
    font-family: 'Switzer-Bold';
    margin: 0 15px 0 0
}

.TravelBox .TravelRight figcaption aside h4 span {
    font-size: 14px;
    font-family: 'Switzer-Regular'
}

.TravelBox .TravelRight figcaption aside button {
    background-color: #EC1E32;
    outline: 0;
    width: 130px;
    color: #fff
}
@charset "utf-8";
/* CSS Document */
/* @import url('animate.css'); */
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');
@import 'animate.css';



@font-face {
    font-family: 'Switzer-Light';
    src: url('../fonts/Switzer-Light.otf');
}

@font-face {
    font-family: 'Switzer-Regular';
    src: url('../fonts/Switzer-Regular.otf');
}

@font-face {
    font-family: 'Switzer-Medium';
    src: url('../fonts/Switzer-Medium.otf');
}

@font-face {
    font-family: 'Switzer-Semibold';
    src: url('../fonts/Switzer-Semibold.otf');
}

@font-face {
    font-family: 'Switzer-Bold';
    src: url('../fonts/Switzer-Bold.otf');
}



body {
    margin: 0;
    padding: 0;
    font-family: 'Switzer-Regular' !important;
    font-size: 14px;
    color: #000;
    font-weight: 400;
    position: relative;
    background-color: #ffffff;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

.clearfix {
    zoom: 1;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
    line-height: 0;
}

.clearfix:after {
    clear: both;
}

.clr,
.clear {
    clear: both;
}

header,
footer,
aside,
article,
section,
nav,
figure {
    position: relative;

}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

ul,
li,
ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none !important;
    cursor: pointer;
}

a:hover,
a:focus {
    text-decoration: none !important;
    outline: none;
}

img {
    border: 0;
    outline: none;
    max-width: 100%;
}

input[type="text"],
input[type="password"],
select,
textarea,
input[type="button"] {
    max-width: 100%;
}

input[type=date].form-control {
    text-transform: uppercase;
}

textarea {
    resize: none;
}

table {
    width: 100%;
}

p {
    margin: 0;
    padding: 0;
}

.fltL {
    float: left;
}

.fltR {
    float: right !important;
}

.padding_none {
    padding: 0 !important;
}

.border_none {
    border: none !important
}

.navbar-toggle {
    background-color: #fff !important;
}

.icon-bar {
    background-color: #000 !important;
}

.CheckBox {
    display: block;
    position: relative;
    padding-left: 33px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
    color: #000;
    margin: 0;
    min-height: 20px;
    line-height: 24px;
    margin: 0 0 10px 0;
}

.CheckBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.CheckBox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 4px;
}

.CheckBox:hover input~.checkmark {
    background-color: #000;
    border-color: #000;
}

.CheckBox input:checked~.checkmark {
    background-color: #000;
}

.CheckBox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(54deg);
}

.CheckBox input:checked~.checkmark:after {
    display: block
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */

.header1 {
    background-color: #000;
}

.header2 {
    background-color: #000;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
}

.header1 .nav-item .nav-link {
    color: #fff;
}

.header2 .nav-item .nav-link {
    color: #fff;
}

.header1.Fixed {
    background-color: #000;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
}

.header2.Fixed {
    background-color: #fff;
}

.header1.Fixed .nav-item .nav-link {
    color: #fff;
}

.header2.Fixed .nav-item .nav-link {
    color: #000;
}

.TransBackground .header1 {
    background-color: transparent;
}

header {
    z-index: 9999;
    position: fixed;
    width: 100%;
}

.TransBackground .header1.Fixed {
    position: fixed !important;
    background-color: #000;
    /* color: #fff!important; */
}

/* header.Fixed .nav-item .nav-link {color: #fff;} */
header nav {
    padding: 0 105px !important;
}

header nav .navbar-brand {
    padding: 0px;
    width: 100px;
}

header .nav-item .nav-link {
    padding: 20px !important;
}

header .nav-item .nav-link {
    font-size: 16px;
    font-family: 'Switzer-Medium';
    /* color: rgba(102, 112, 133, 1); */
    /* color: #000; */
}

/* header .nav-item .nav-link.active {
    color: rgba(3, 42, 82, 1);
    font-weight: 600;
} */

.fixed-top__scrolled {
    background: linear-gradient(#2d2d2d, #2d2d2d);
}

header nav .UserLogin {
    display: flex;
    align-items: center;
}

header nav .UserLogin figure {
    margin: 0 0 0 30px;
    cursor: pointer;
}

header nav .UserLogin .Cart {
    position: relative;
}

header nav .UserLogin .CartOverlay {
    position: absolute;
    color: #fff;
    background-color: rgba(243, 4, 44, 1);
    top: -10px;
    right: -10px;
    border-radius: 50%;
    padding: 0px 7px;
    font-size: 14px;
    line-height: 20px;
}

.NavbarLogin a:first-child {
    color: rgba(3, 42, 82, 1);
    font-weight: 400;
    font-size: 16px;
    margin-right: 20px;
    display: inline-block;
    color: white;
}

.SupplierLoginBtn {
    border: 2px solid rgba(243, 4, 44, 1);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    color: rgba(243, 4, 44, 1);
    display: inline-block;
    padding: 10px 20px;
}

section {
    background-color: #fff;
}

.hero {
    width: 100%;
    height: 475px;
    overflow: hidden;
    position: relative;
    padding-top: 200px;
}

.hero::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
}

.hero video {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

.hero-content {
    z-index: 999;
    position: sticky;
}

.hero h1 {
    color: #fff;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    z-index: 10;
    position: sticky;
    text-align: center;
    margin: 0 0 20px 0;
}

.hero h1 span {
    color: rgba(243, 4, 44, 1);
}

.hero .SignUpBtn {
    position: fixed;
    right: 0;
    top: 45%;
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: #fff;
    font-family: 'Switzer-Medium';
    font-size: 16px;
    padding: 25px 15px;
    display: inline-block;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    z-index: 99;
}

.OurPartnersArea.ConnectWorldArea {
    padding: 0px 75px 25px;
}

.ConnectWorldArea .ConnectTabsArea {
    border-radius: 20px;
    background-color: #fff;
    position: relative;
    top: -50px;
    z-index: 99;
}

.ConnectWorldArea .ConnectTabsArea .CommonTabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 30px;
}

.ConnectWorldArea .ConnectTabsArea .nav-tabs .nav-link:hover {
    border: none;
}

.ConnectTabsArea .CommonTabs .nav .nav-item .nav-link {
    color: rgba(3, 42, 82, 1);
    opacity: 50%;
}

.ConnectTabsArea .CommonTabs .nav .nav-item .nav-link.active {
    border: none;
    border-bottom: 2px solid rgba(243, 4, 44, 1) !important;
    color: black !important;
    opacity: 1;
}

.ConnectWorldArea .nav-tabs .nav-item .nav-link {
    color: rgba(3, 42, 82, 1);
    opacity: 50%;
}

.ConnectWorldArea .nav-tabs .nav-item .nav-link.active {
    color: rgba(3, 42, 82, 1);
    opacity: 1;
}

.ConnectWorldArea .tab-content {
    padding: 5px 10px;
}

.ConnectWorldArea .tab-content .tab-pane h5 {
    font-family: 'Switzer-Semibold';
    font-size: 20px;
    color: rgba(15, 23, 42, 1);
    margin: 0 0 35px 0;
}

.HappyClientArea {
    background-color: rgba(3, 42, 82, 1);
    padding: 40px 100px;
}

.HappyClientArea .Counter-Box {
    margin: 0 0 40px 0;
    text-align: center;
}

.HappyClientArea h1 {
    font-family: 'SourceSerifPro-Semiboldss';
    font-size: 24px;
    color: #fff;
    margin: 0 0 0px 0;
}

.HappyClientArea h1 span {
    font-size: 54px;
}

.HappyClientArea p {
    color: rgba(255, 255, 255, 0.6);
    font-family: 'Switzer-Regular';
    font-size: 16px;
}


.BecomeEmpowerArea {
    padding: 75px 100px;
}

.BecomeEmpowerArea .BecomeEmpowerLeft {
    display: flex;
}

.BecomeEmpowerArea .BecomeEmpowerLeft figure img {
    border-radius: 15px;
}

.BecomeEmpowerArea .BecomeEmpowerLeft figure:first-child {
    margin: 65px 0 0 0;
}

.BecomeEmpowerArea .BecomeEmpowerLeft figure:nth-child(2) {
    margin: 0 15px;
}

.BecomeEmpowerArea .BecomeEmpowerLeft figure:last-child {
    margin: 40px 0 0 0;
}

.BecomeEmpowerArea .BecomeEmpowerRight {
    padding: 50px 0 0 35px;
}

.BecomeEmpowerArea .BecomeEmpowerRight h1 {
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    font-weight: 600;
    letter-spacing: -0.7px;
    margin: 0 0 30px 0;
}

.BecomeEmpowerArea .BecomeEmpowerRight h1 span {
    color: rgba(243, 4, 44, 1);
}

.BecomeEmpowerArea .BecomeEmpowerRight ul li {
    color: rgba(15, 23, 42, 1);
    margin: 0 0 10px 0;
    font-size: 16px;
    font-family: 'Switzer-Regular';
    font-weight: 400;
    display: flex;
    align-items: flex-start;
}

.BecomeEmpowerArea .BecomeEmpowerRight ul li i {
    margin: 5px 10px 0 0;
    color: rgba(15, 23, 42, 1);
}


.OurSolutionsArea {
    padding: 75px 100px;
}

.OurSolutionsArea .OurSolutionsLeft {
    padding: 0 100px 0 0;
}

.OurSolutionsArea .OurSolutionsLeft .HowWeWork {
    color: rgba(243, 4, 44, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    margin: 0 0 25px 0;
    display: inline-block;
}

.OurSolutionsArea .OurSolutionsLeft h1 {
    font-size: 48px;
    margin: 0 0 30px 0;
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-Semibold';
}

.OurSolutionsArea .OurSolutionsLeft h1 span {
    color: rgba(243, 4, 44, 1);
}

.OurSolutionsArea .OurSolutionsLeft p {
    color: rgba(15, 23, 42, 1);
    font-family: 'Switzer-Regular';
    padding: 0 0 40px 0;
    font-size: 16px;
    border-bottom: 1px dashed rgba(102, 112, 133, 1);
}

.OurSolutionsArea .OurSolutionsLeft a {
    background-color: rgba(243, 4, 44, 1);
    display: inline-block;
    color: #fff;
    font-family: 'Switzer-Medium';
    font-size: 16px;
    padding: 10px 30px;
    margin: 50px 0 0 0;
}

.OurSolutionsArea .OurSolutionsRight {
    padding: 10px 0 0 35px;
}

.OurSolutionsArea .OurSolutionsRight .OsrBox {
    border-left: 1px dashed rgba(102, 112, 133, 1);
    position: relative;
    padding: 0 0 35px 50px;
}

.OurSolutionsArea .OurSolutionsRight .OsrBox:last-child {
    border-left: none;
    padding-bottom: 0px;
}

.OurSolutionsArea .OurSolutionsRight .OsrBox span {
    position: absolute;
    left: -25px;
    top: 0;
    width: 49px;
    height: 49px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(3, 42, 82, 1);
    color: #fff;
    border-radius: 50%;
    font-size: 20px;
    font-family: 'Switzer-Medium';
}

.OurSolutionsArea .OurSolutionsRight .OsrBox h2 {
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-Semibold';
    font-size: 28px;
    margin: 0 0 10px 0;
}

.OurSolutionsArea .OurSolutionsRight .OsrBox p {
    color: rgba(15, 23, 42, 1);
    font-size: 16px;
}

.WhyPartnerWithArea {
    padding: 75px 100px 25px;
    background-color: #fff;
}

.WhyPartnerWithArea .section-title {
    text-align: center;
    margin: 0 0 75px 0;
}

.WhyPartnerWithArea .section-title h1 {
    color: #032A52;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.WhyPartnerWithArea .section-title span {
    color: rgba(243, 4, 44, 1);
}

.WhyPartnerWithArea .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.WhyPartnerWithArea .WhyPartnerBox {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.WhyPartnerWithArea .WhyPartnerBox span {
    display: inline-block;
    margin: 0 0 15px 0;
    min-height: 115px;
}

.WhyPartnerWithArea .WhyPartnerBox h6 {
    color: #0F172A;
    font-weight: 700;
    line-height: 26px;
    font-size: 18px;
    font-family: 'Switzer-bold';
    color: #032A52;
    text-align: center;
}



.CompleteBusinessArea {
    padding: 75px 100px 50px;
    background-color: #fff;
}

.CompleteBusinessArea .section-title {
    text-align: center;
    margin: 0 0 75px 0;
}

.CompleteBusinessArea .section-title h1 {
    color: #032A52;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.CompleteBusinessArea .section-title span {
    color: rgba(243, 4, 44, 1);
}

.CompleteBusinessArea .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.CompleteBusinessArea .CompleteBusinessBox {
    background-color: #F1F5F9;
    text-align: center;
    height: calc(100% - 25px);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 50px 25px 25px;
    margin: 0 0 25px 0;
}

.CompleteBusinessArea .CompleteBusinessBox span {
    display: inline-block;
    margin: 0 0 15px 0;
}

.CompleteBusinessArea .CompleteBusinessBox h6 {
    color: #0F172A;
    font-weight: 700;
    font-size: 18px;
    font-family: 'Switzer-bold';
    text-align: center;
}


/* .OurReachArea {padding: 75px 0px 0px;} */
.OurReachArea .section-title {
    text-align: center;
    margin: 0 0 75px 0;
}

.OurReachArea .section-title h1 {
    color: rgba(0, 0, 0, 1);
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.OurReachArea .section-title span {
    color: rgba(243, 4, 44, 1);
}

.OurReachArea .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}



.AdventureActivity {
    padding: 75px 100px;
    background-color: #fff;
}

.AdventureActivity .section-title {
    text-align: center;
    margin: 0 0 75px 0;
}

.AdventureActivity .section-title h1 {
    color: rgba(0, 0, 0, 1);
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.AdventureActivity .section-title span {
    color: rgba(243, 4, 44, 1);
}

.AdventureActivity .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.AdventureActivity .AdventureBox {
    border-radius: 4px;
    border: 1px solid rgba(211, 211, 211, 1);
    padding: 25px 20px;
    text-align: center;
    height: calc(100% - 50px);
    margin: 0 0 50px 0;
}

.AdventureActivity .AdventureBox .Icon {
    background-color: rgba(222, 238, 255, 1);
    width: 120px;
    height: 120px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 15px 0;
    border-radius: 50%;
}

.AdventureActivity .AdventureBox h6 {
    color: rgba(26, 26, 26, 1);
    font-size: 18px;
    font-family: 'Switzer-bold';
    margin: 0 0 20px 0;
}

.AdventureActivity .AdventureBox p {
    color: rgba(51, 51, 51, 1);
    font-family: 'Switzer-Regular';
    font-size: 16px;
}

/* .AdventureActivity .AdventureActivityLeft {margin: 0 0 50px 0;} */
.AdventureActivity .AdventureActivityLeft h1 {
    color: rgba(3, 42, 82, 1);
    font-size: 48px;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
}

.AdventureActivity .AdventureActivityLeft h1 span {
    color: rgba(243, 4, 44, 1);
    font-size: 48px;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
}

/* .AdventureActivity .AdventureActivityRight {margin: 0 0 50px 0;} */
.AdventureActivity .AdventureActivityRight p {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Regular';
    font-size: 16px;
    text-align: right;
}


.ChoosePackageArea {
    padding: 75px 100px;
}

.ChoosePackageArea .ChoosePackageLeft {
    margin: 0 0 50px 0;
}

.ChoosePackageArea .ChoosePackageLeft h1 {
    color: rgba(3, 42, 82, 1);
    font-size: 48px;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
}

.ChoosePackageArea .ChoosePackageLeft h1 span {
    color: rgba(243, 4, 44, 1);
    font-size: 48px;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
}

.ChoosePackageArea .ChoosePackageRight {
    margin: 0 0 50px 0;
}

.ChoosePackageArea .ChoosePackageRight p {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Regular';
    font-size: 16px;
    text-align: right;
}

.ChoosePackageArea .ChoosePackageBox {
    border-radius: 20px;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.08);
    transition: 0.5 all ease-in;
    background-color: rgba(241, 245, 249, 1);
    padding: 25px;
}

.ChoosePackageArea .ChoosePackageBox figure {
    position: relative;
    border-radius: 20px;
}

.ChoosePackageArea .ChoosePackageBox figure img {
    width: 100%;
}

.ChoosePackageArea .ChoosePackageBox figure::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.59), rgba(0, 0, 0, 1));
}

.ChoosePackageArea .ChoosePackageBox figure span:last-child {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 10px;
    font-family: 'Switzer-Bold';
    color: #fff;
    font-size: 20px;
}

.ChoosePackageArea .ChoosePackageBox h2 {
    font-size: 42px;
    margin: 0 0 25px 0;
    color: rgba(3, 42, 82, 1);
    letter-spacing: -0.67px;
    font-family: 'Switzer-Semibold';
}

.ChoosePackageArea .ChoosePackageBox span.Center {
    border: 1px solid rgba(220, 220, 220, 1);
    border-radius: 2px;
    background-color: #fff;
    width: 100%;
    padding: 15px 0;
    margin: 0 0 25px 0;
    display: inline-block;
    color: rgba(3, 42, 82, 1);
    text-align: center;
    letter-spacing: -0.25px;
    font-family: 'Switzer-Medium';
}

.ChoosePackageArea .ChoosePackageBox ul {
    border-top: 1px solid rgba(120, 120, 120, 0.24);
    padding: 20px 0 0 0;
}

.ChoosePackageArea .ChoosePackageBox ul li {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Regular';
    font-size: 14px;
    margin: 0 0 10px 0;
}

.ChoosePackageArea .ChoosePackageBox ul li img {
    margin: 0 10px 0 0;
}




.ChoosePackageArea .ChoosePackageBox:hover {
    background-color: #fff;
    border-radius: 5px;
}

.ChoosePackageArea .ChoosePackageBox figure span:first-child {
    border-radius: 5px;
    background-color: rgba(235, 251, 243, 1);
    color: rgba(0, 204, 97, 1);
    font-weight: 700;
    letter-spacing: 1.2px;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 15px;
    font-family: 'Switzer-Bold';
    font-size: 16px;
    text-transform: uppercase;
}

.ChoosePackageArea .ChoosePackageBox:hover span.Center {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0px;
}



.BestHotelDealsArea {
    padding: 50px 100px 75px;
}

.BestHotelDealsArea .BestHotelDealsLeft h1 {
    color: rgba(3, 42, 82, 1);
    font-size: 48px;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.BestHotelDealsArea .BestHotelDealsLeft h1 span {
    color: rgba(243, 4, 44, 1);
    font-size: 48px;
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
}

.BestHotelDealsArea .BestHotelDealsLeft {
    padding: 0 50px 0 0;
}

.BestHotelDealsArea .BhdaBox {
    margin: 0 0 50px 0;
    position: relative;
    padding: 20px 0 15px 15px;
}

.BestHotelDealsArea .BhdaBox .BhdaBoxOverlay {
    position: absolute;
    display: inline-flex;
    opacity: 0;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Switzer-Semibold';
    font-size: 18px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: rgba(243, 4, 44, 1);
    bottom: -60px;
    right: -60px;
    transform: rotate(15deg);
}

.BestHotelDealsArea .BhdaBox:hover .BhdaBoxOverlay {
    opacity: 1;
}

.BestHotelDealsArea .BhdaBox:hover {
    background-color: #fff;
    border-radius: 4px;
    padding: 20px 0 15px 15px;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
}

.BestHotelDealsArea .BhdaBox h5 {
    color: rgba(0, 0, 0, 1);
    font-size: 24px;
    margin: 0 0 10px 0;
    font-family: 'Switzer-Semibold';
}

.BestHotelDealsArea .BhdaBox p {
    color: rgba(102, 112, 133, 1);
    margin: 0 0 30px 0;
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.BestHotelDealsArea .BhdaBox .BhdaDetails {
    display: flex;
    align-items: center;
}

.BestHotelDealsArea .BhdaBox .BhdaDetails p {
    font-size: 16px;
    margin-bottom: 0px;
}

.BestHotelDealsArea .BhdaBox .BhdaDetails p:last-child {
    margin: 0 0 0 30px;
}

.BestHotelDealsArea .BhdaBox .BhdaDetails p span {
    margin: 0 10px 0 0;
}

.BestHotelDealsArea .BestHotelDealsRight {
    text-align: right;
}

.FindingYourDreams {
    padding: 50px 100px 75px;
}

.FindingYourDreams .section-title {
    text-align: left;
    margin: 0 0 50px 0;
}

.FindingYourDreams .section-title h1 {
    color: rgba(0, 0, 0, 1);
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.FindingYourDreams .section-title span {
    color: rgba(243, 4, 44, 1);
}

.FindingYourDreams .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.FindingYourDreams .WhyChooseUs {
    color: rgba(243, 4, 44, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    margin: 0 0 25px 0;
    display: inline-block;
    background-color: rgba(255, 242, 242, 1);
    padding: 10px 15px;
    letter-spacing: 2px;
}

.FindingYourDreams .FindingDreamsBox {
    background-color: #fff;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
    padding: 50px 20px 45px 20px;
}

.FindingYourDreams .FindingDreamsBox .Icon {
    margin: 0 0 25px 0;
    display: inline-block;
}

.FindingYourDreams .FindingDreamsBox h5 {
    color: rgba(0, 0, 0, 1);
    font-size: 22px;
    font-family: 'Switzer-Medium';
    margin: 0 0 10px 0;
}

.FindingYourDreams .FindingDreamsBox p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    line-height: 27px;
    font-family: 'Switzer-Medium';
}

.FindingYourDreams .FindingDreamsBox.Second {
    margin-top: 50px;
}

.FindingYourDreams .FindingDreamsBox.Third {
    margin-top: 100px;
}

.TestimonialsArea {
    padding: 100px 125px 75px;
    background-color: rgba(241, 245, 249, 1);
}

.TestimonialsArea .TestimonialSpan {
    background-color: rgba(255, 242, 242, 1);
    border-radius: 4px;
    padding: 10px 15px;
    color: rgba(243, 4, 44, 1);
    font-size: 16px;
    font-family: 'Switzer-Medium';
    margin: 0px auto 25px;
    text-transform: uppercase;
    display: inline-block;
}

.TestimonialsArea .section-title {
    text-align: center;
    margin: 0 0 100px 0;
}

.TestimonialsArea .section-title h1 {
    color: rgba(3, 42, 82, 1);
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.TestimonialsArea .section-title span {
    color: rgba(243, 4, 44, 1)
}

.TestimonialsArea .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.TestimonialsArea .TestimonialsBox h5 {
    color: rgba(3, 42, 82, 1);
    letter-spacing: -0.33px;
    font-family: 'Switzer-Medium';
    font-size: 20px;
    margin: 0 0 45px 0;
}

.TestimonialsArea .TestimonialsBox .quote {
    width: 59px;
    display: inline-block;
}

.TestimonialsArea .TestimonialsBox hr {
    background-color: rgba(120, 120, 120, 1);
    margin-bottom: 2rem;
}

.TestimonialsArea .TestimonialsBox .TestimonialsDetails {
    position: relative;
    padding: 0 0 0 60px;
}

.TestimonialsArea .TestimonialsBox .TestimonialsDetails figure {
    position: absolute;
    left: 0;
}

.TestimonialsArea .TestimonialsBox .TestimonialsDetails h6 {
    color: rgba(3, 42, 82, 1);
    font-size: 15px;
    font-family: 'Switzer-Bold';
    font-size: 15px;
    margin: 0 0 5px 0;
}

.TestimonialsArea .TestimonialsBox .TestimonialsDetails p {
    color: rgba(64, 64, 64, 1);
    font-size: 15px;
}


.OurPartnersArea {
    padding: 50px 75px 50px;
}

.OurPartnersArea.OurPartnersArea .section-title {
    padding-bottom: 50px;
    margin-bottom: 0px;
}

.OurPartnersArea .section-title {
    text-align: center;
    margin: 0 0 50px 0;
}

.OurPartnersArea .section-title h1 {
    color: rgba(3, 42, 82, 1);
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.OurPartnersArea .section-title span {
    color: rgba(243, 4, 44, 1)
}

.OurPartnersArea .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.OurPartnersArea .nav-tabs {
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
}

.OurPartnersArea .nav-tabs .nav-link {
    border: none;
    color: rgba(15, 23, 42, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
    border-radius: 0px;
}

.OurPartnersArea .nav-tabs .nav-link.active {
    color: rgba(243, 4, 44, 1);
    border: 1px solid rgba(243, 4, 44, 1);
    border-radius: 2px;
}

.OurPartnersArea .PartnersTabs .tab-content .tab-pane .tab-content-details {
    display: flex;
    padding: 50px 0 0 0;
    justify-content: center;
}

.OurPartnersArea .Filter .ShowHotels {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: #fff;
    font-family: 'Switzer-Medium';
    text-align: center;
    font-size: 16px;
    padding: 12px 10px;
    width: 100%;
    display: inline-block;
}

.OurPartnersArea .Filter {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.OurPartnersArea .Filter .form-group {
    width: 100%;
    margin: 0 10px 0 0;
}

.OurPartnersArea .Filter .form-group .InputBox.First {
    min-width: 250px;
}

.OurPartnersArea .Filter .form-group .InputBox {
    border: 1px solid rgba(121, 116, 126, 1);
    padding: 10px 15px;
    border-radius: 4px;
    position: relative;
    display: block;
}

.OurPartnersArea .Filter .form-group .InputBox.SelectBox {
    padding: 0px;
}

.OurPartnersArea .Filter .form-group .InputBox .form-control-date {
    width: 100%;
    border: none;
    outline: none;
}

.OurPartnersArea .Filter .form-group .InputBox h6 {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    background-color: #fff;
    font-size: 14px;
    font-family: 'Switzer-Medium';
}

.OurPartnersArea .Filter .form-group .InputBox .form-control-date,
.OurPartnersArea .Filter .form-group .InputBox select {
    border: none;
    outline: none;
    width: 100%;
}

.OurPartnersArea .Filter .form-group .InputBox select {
    padding: 12px 10px 10px 40px;
    border-radius: 4px;
}

.OurPartnersArea .Filter .form-group .InputBox select {
    appearance: none;
}

.OurPartnersArea .Filter .form-group .InputBox .form-control-date:focus {
    outline: none;
}

.OurPartnersArea .Filter .form-group .InputBox .FilterIcon {
    position: absolute;
    right: 12px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
}

.OurPartnersArea .Filter .form-group .InputBox .StaffIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.OurPartnersArea .CommonInputBox .form-control {
    padding: 10px 10px 10px 40px;
    height: auto;
}

.CommonInputBox.Custom .form-control {
    padding: 15px 10px 15px 40px;
    height: auto;
    background-color: #fff;
}

.CommonInputBox.Label {
    color: rgba(28, 27, 31, 1);
    font-family: 'Switzer-Medium';
}

.OurPartnersArea .CommonInputBox.First {
    min-width: 275px;
}

.FlightListingFilter {
    margin: 0 0 40px 0;
}

.FlightListingFilter .Filter .ShowHotels {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: #fff;
    font-family: 'Switzer-Medium';
    width: 300px;
    text-align: center;
    font-size: 16px;
    padding: 9px 15px;
    display: inline-block;
}

.file .FlightListingFilter .Filter .ShowHotels {
    width: auto;
}

.FlightListingFilter .Filter .ShowHotels span {
    margin: 0 5px 0 0;
}

.FlightListingFilter .Filter {
    display: flex;
    align-items: flex-start;
}

.FlightListingFilter .Filter .form-group {
    flex: 1;
    margin: 0 15px 0 0;
}

.FlightListingFilter .Filter .form-group .InputBox {
    border: 1px solid rgba(121, 116, 126, 1);
    padding: 10px 15px;
    border-radius: 4px;
    position: relative;
    display: block;
}

.FlightListingFilter .Filter .form-group .InputBox.First {
    width: 250px;
}

.FlightListingFilter .Filter .form-group .InputBox.SelectBox {
    padding: 0px;
}

.FlightListingFilter .Filter .form-group .InputBox h6 {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    background-color: #fafbfc;
    font-size: 14px;
    font-family: 'Switzer-Medium';
}

.FlightListingFilter .Filter .form-group .InputBox .form-control-date,
.FlightListingFilter .Filter .form-group .InputBox select {
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
}

.FlightListingFilter .Filter .form-group .InputBox select {
    padding: 10px 15px 10px 40px;
    border-radius: 4px;
    background-color: transparent;
}

.FlightListingFilter .Filter .form-group .InputBox select {
    appearance: none;
}

.FlightListingFilter .Filter .form-group .InputBox .form-control-date:focus {
    outline: none;
}

.FlightListingFilter .Filter .form-group .InputBox .FilterIcon {
    position: absolute;
    right: 12px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
}

.FlightListingFilter .Filter .form-group .InputBox .StaffIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

footer {
    background-color: rgba(3, 42, 82, 1);
    padding: 75px 100px 50px;
}

footer .footer-top {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 0 75px 0;
}

footer .footer-top .footer-about a {
    margin: 0 0 25px 0;
    display: inline-block;
    width: 100px;
}

footer .footer-top .footer-about p {
    color: rgba(152, 162, 179, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    line-height: 25px;
}

footer .footer-top .footer-links h4,
footer .footer-top .footer-contact h4 {
    color: rgba(255, 255, 255, 1);
    font-family: 'Switzer-Medium';
    font-size: 20px;
    margin: 0 0 25px 0;
}

footer .footer-top .footer-links ul li a,
footer .footer-top .footer-contact p {
    font-size: 16px;
    color: rgba(152, 162, 179, 1);
    margin: 0 0 15px 0;
    display: inline-block;
    font-family: 'Switzer-Medium';
}

footer .footer-top .footer-links ul li a img {
    margin: 0 20px 0 0;
}

footer .footer-bottom {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0 0;
}

footer .footer-bottom ul {
    display: flex;
}

footer .footer-bottom ul li {
    margin: 0 20px 0 0;
}

footer .footer-bottom ul li a,
footer .footer-bottom p {
    font-size: 16px;
    color: rgba(152, 162, 179, 1);
    margin: 0 0 15px 0;
    display: inline-block;
    font-family: 'Switzer-Medium';
    position: relative;
}

footer .footer-bottom ul li a:last-child {
    list-style-type: circle;
}


.FilterProductsArea {
    padding: 75px 100px;
    background-color: #f3f3f3;
    position: relative;
}

.FilterProductsAreaOverlay {
    position: absolute;
    right: 100px;
    top: 25px;
    color: #000;
    font-family: 'Switzer-Medium';
    font-size: 14px;
    display: inline-block;
}

.FilterLeftArea {
    border-right: 1px solid rgba(0, 0, 0, 0.25);
    padding: 0 20px 0 0;
}

.FilterLeftArea h4 {
    font-size: 20px;
    font-family: 'Switzer-Semibold';
    color: #000;
    margin: 0 0 25px 0;
}

.FilterLeftArea .LeftCommonBox:last-child {
    border-bottom: none;
}

/* Product page css start  */

.ProductMain {
    background-color: #2c2c2c;
}

.ShowingRightArea .ShowingPlacesRecommend {
    display: flex;
    justify-content: space-between;
    margin: 0 0 20px 0;
}

.ShowingRightArea .ShowingPlacesRecommend h6 select {
    border: none;
    background-color: transparent;
}

.ShowingRightArea .ShowingPlacesRecommend h6 select:focus {
    border: none;
    outline: none;
}

.ShowingRightArea .ShowingPlacesRecommend h6 {
    font-family: 'Switzer-Semibold';
}

.ShowingRightArea .ShowingPlacesRecommend h6 span {
    color: rgba(243, 4, 44, 1);
}

.ShowingRightArea .ShowingPlacesRecommend h6:last-child {
    font-family: 'Switzer-Light';
}

.ShowingRightArea .ShowingPlacesRecommend h6 select {
    font-family: 'Switzer-Semibold';
}

.ShowingRightArea .ShowMore {
    color: #667085;
    border: 1px solid #667085;
    display: inline-block;
    width: 100%;
    padding: 10px 20px;
    font-family: 'Switzer-Medium';
    text-align: center;
    font-size: 18px;
    border-radius: 2px;
}

.ShowingRightArea>h6 {
    color: #000;
    font-size: 14px;
    font-family: 'Switzer-Semibold';
}

.ShowingRightArea>h6 span {
    color: rgba(243, 4, 44, 1);
    font-size: 14px;
    font-family: 'Switzer-Semibold';
}

.ProductBox {
    background-color: #fff;
    box-shadow: 0 4px 30px 0px rgba(0, 0, 0, 0.08);
    padding: 20px 20px 15px 15px;
    border-radius: 8px;
    margin: 0 0 25px 0;
}

.ProductBox .ProductLeft {
    position: relative;
}

.ProductBox .ProductLeft .ProductLeftOverlay {
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.ProductBox .ProductLeft .ProductLeftOverlay .PloImages {
    display: flex;
}

.ProductBox .ProductLeft .ProductLeftOverlay p {
    font-size: 16px;
    color: #fff;
    margin: 5px 0 0 0;
}

.ProductBox .ProductLeft figure {
    border-radius: 4px 0 0 4px;
    margin-bottom: 0px !important;
    position: relative;
    margin-left: -8px;
}

.ProductBox .ProductLeft figure img {
    width: 100%;
}

.ProductBox .ProductLeft figure:before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background-image: linear-gradient(rgba(51, 51, 51, 0), rgba(51, 51, 51, 1));
    content: "";
}

.ProductBox .ProductRight .ProductDetails {
    position: relative;
    border-bottom: 1px solid rgba(229, 231, 235, 1);
    padding-bottom: 10px;
}

.ProductBox .ProductRight .ProductDetails .ProductRightOverlay {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.ProductBox .ProductRight .ProductDetails .ProductRightOverlay span {
    border-radius: 10px;
    border: 0.5px solid rgba(229, 231, 235, 1);
    width: 48px;
    height: 48px;
    margin: 0 10px 0 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ProductBox .ProductRight .ProductDetails .Zoonature {
    width: 70%;
}

.ProductBox .ProductRight .ProductDetails .Zoonature span {
    color: #000;
    font-size: 12px;
    font-family: 'Switzer-Medium';
}

.ProductBox .ProductRight .ProductDetails .Zoonature p {
    color: #000;
    font-size: 12px;
    font-family: 'Switzer-Light';
}

.ProductRightOverlay.Option {
    display: flex;
    flex-direction: column;
}

.ProductRightOverlay h6 {
    color: rgba(0, 0, 0, 1);
    font-size: 13px;
    font-family: 'Switzer-Semibold';
    display: block;
}

.AddToCartBtn {
    display: inline-block;
    background-color: rgba(236, 30, 50, 1);
    border-radius: 5px;
    padding: 13px 20px;
    color: #fff;
}

.AddToCartBtn i {
    margin: 0 10px 0 0;
}

.AddToCartBtn:hover {
    color: #fff;
}

.ProductBox .ProductRight .ProductDetails h6 {
    font-family: 'Switzer-Medium';
    color: #000;
    font-size: 18px;
    margin: 10px 0 15px 0;
}

.ProductBox .ProductRight .ProductDetails h6 span {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Light';
    margin: 0 0 0 5px;
}

.ProductBox .ProductRight .ProductDetails p {
    font-family: 'Switzer-Regular';
    color: rgba(102, 112, 133, 1);
    font-size: 14px;
    margin: 0 0 5px 0;
}

.ProductBox .ProductRight .ProductDetails p i {
    margin: 0 10px 0 0;
}

.ProductBox .ProductRight .ProductDetails ul {
    display: flex;
    margin: 0 0 15px 0;
}

.ProductBox .ProductRight .ProductDetails ul li {
    margin: 0 15px 0 0;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    margin: 0 15px 0 0;
}

/* .ProductBox .ProductRight .ProductDetails ul li a{} */
.ProductBox .ProductRight .ProductDetails ul li span {
    font-family: 'Switzer-Semibold';
    font-size: 15px;
}

.ProductBox .ProductRight .ProductDetails ul li img {
    margin: 0 10px 0 0;
}

.ProductBox .ProductRight .ProductDetails ul li i {
    color: rgba(252, 211, 77, 1);
    margin: 0 10px 0 0;
    font-size: 14px;
}

.ProductBox .ProductRight .ProductDetails .HotelFacility span {
    color: rgba(3, 42, 82, 1);
    font-weight: 500;
    display: inline-block;
    font-family: 'Switzer-Medium';
    background-color: rgba(234, 240, 255, 1);
    font-size: 12px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 5px;
}

.ProductBox .ProductRight .PrpArea .StartingFrom {
    text-align: right;
    display: block;
    font-size: 13px;
    margin: 5px 0 0 0;
}

.PrpBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.PrpBox span {
    font-size: 14px;
    font-family: 'Switzer-Semibold';
}

.PrpBox h5 {
    font-family: 'Switzer-Medium';
}

.PrpBox .RatingStars {
    margin: 0 5px;
}

.PrpBox .RatingStars i {
    color: rgba(43, 103, 246, 0.16);
}

.PrpBox .RatingStars i.checked {
    color: rgba(252, 211, 77, 1);
}

.PrpBox h2 {
    font-family: 'Switzer-bold';
    font-size: 26px;
}

.PrpBox h2 span {
    font-family: 'Switzer-Regular';
    font-size: 14px;
}

.LeftCommonBox {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin: 0 0 25px 0;
}

.LeftCommonBox .LeftCommonHeading {
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px 0;
    cursor: pointer;
}

.LeftCommonBox .LeftCommonHeading h6 {
    color: #000;
    font-size: 16px;
    font-family: 'Switzer-Semibold';
}

.LeftCommonBox .LeftCommonHeading i {
    font-size: 18px;
    transition: all 0.5s;
}

.LeftCommonBox .LeftCommonContent {
    padding: 0 0 20px 0;
}

.LeftCommonBox .LeftCommonContent .fa-heart {
    color: rgba(236, 30, 50, 1);
    font-size: 16px;
}

.LeftCommonHeading[aria-expanded="false"] span i {
    transform: rotate(180deg);
}

.LeftCommonBox .card-body {
    padding: 0.25rem 0 0 0 !important;
    background-color: transparent !important;
    border: none !important;
}


.CommonInputBox {
    border: 1px solid rgba(121, 116, 126, 1);
    border-radius: 4px;
    position: relative;
}

.CommonInputBox h6 {
    font-family: 'Switzer-Medium';
    font-size: 14px;
    position: absolute;
    left: 10px;
    top: -10px;
    background-color: #fafbfc;
    padding: 0 5px;
}

.CommonInputBox .CibLeftOverlay {
    left: 15px;
    width: 22px;
}

.CommonInputBox .CibRightOverlay {
    right: 15px;
    cursor: pointer;
}

.CommonInputBox .CibRightOverlay .fa-search {
    font-size: 18px;
    color: rgba(28, 27, 31, 1);
}

.CommonInputBox .CibLeftOverlay,
.CommonInputBox .CibRightOverlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.CommonInputBox .form-control {
    padding: 15px 10px 15px 40px;
    color: #333333;
    background-color: transparent;
    height: auto;
}

.CommonInputBox .form-control::placeholder {
    color: #333333;
}

.CommonInputBox .form-control:focus {
    box-shadow: none;
}


.CommonSelectBox {
    border: 1px solid rgba(121, 116, 126, 1);
    border-radius: 4px;
    position: relative;
}

.CommonSelectBox h6 {
    font-family: 'Switzer-Medium';
    font-size: 14px;
    position: absolute;
    left: 10px;
    top: -10px;
    background-color: #f3f3f3;
    padding: 0 5px;
}

.CommonSelectBox .CibRightOverlay {
    right: 15px;
    cursor: pointer;
}

.CommonSelectBox .CibRightOverlay .fa-angle-down {
    font-size: 22px;
    color: rgba(28, 27, 31, 1);
}

.CommonSelectBox .CibLeftOverlay,
.CommonSelectBox .CibRightOverlay {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.CommonSelectBox select {
    padding: 15px 10px 15px 20px;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 4px;
    appearance: none;
}

.CommonSelectBox select:focus {
    box-shadow: none;
}


.RatingCheckboxs ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    margin-left: 5px !important;
}

.RatingCheckboxs ul li {
    width: 40px;
    height: 32px;
    padding: 0;
    position: relative;
    margin: 0 10px 0 0;
}

.RatingCheckboxs ul li input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: pointer;
    opacity: 0;
}

.RatingCheckboxs ul li aside {
    border: 1px solid rgba(3, 42, 82, 1);
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.RatingCheckboxs ul li aside label {
    display: block;
    font-size: 13px;
    color: rgba(3, 42, 82, 1);
    font-family: "Switzer-Medium";
    margin-bottom: 0px;
}

.RatingCheckboxs ul li input:checked~aside {
    border-color: red;
}



.AccountHistoryFilter .TopMainBox {
    background-color: transparent !important;
    padding: 0;
    box-shadow: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    margin: 0px;
}

.AccountHistoryFilter .TopMainBox .Filter {
    margin: 0;
}

.AccountHistoryFilter .TopMainBox .Filter .form-group {
    margin: 0 10px 0 0;
}

.AccountHistoryFilter .TopMainBox .Filter .form-group .InputBox {
    background-color: #fff;
}

.AccountHistoryFilter .TopMainBox .Filter .form-group .InputBox h6 {
    background-color: #f3f3f3;
}

.AccountHistoryFilter .TopMainBox .CommonInputBox {
    background-color: #fff;
    min-width: 300px;
}

.AccountHistoryFilter .TopMainBox .CommonInputBox .form-control {
    padding: 10px 10px 10px 15px;
}

.AccountHistoryFilter .TopMainBox .UpcomingExportExcel select {
    border: none;
    background-color: transparent;
    margin: 0 20px 0 0;
    font-size: 16px;
}

.AccountHistoryFilter .TopMainBox .UpcomingExportExcel select:focus {
    outline: none;
}

.AccountHistoryFilter .TopMainBox .UpcomingExportExcel .ExportToExcelBtn {
    padding: 12px 25px;
    border-radius: 4px;
    border: 0.5px solid rgba(201, 202, 203, 1);
    font-family: 'Switzer-Light';
    color: #000;
    font-size: 14px;
}

.AccountHistoryFilter .TopMainBox .UpcomingExportExcel .ExportToExcelBtn img {
    margin: 0 10px 0 0;
    width: 26px;
}

.AccountHistoryFilter .TopMainBox .CommonInputBox h6 {
    background-color: #f3f3f3;
}

/* 
.AccountHistoryFilter .TopMainBox {background-color: transparent!important; padding: 0; box-shadow: none; width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 40px 0; margin: 0px;}
.AccountHistoryFilter .TopMainBox .Filter {margin: 0;}
.AccountHistoryFilter .TopMainBox .Filter .form-group {margin: 0 10px 0 0 ;}
.AccountHistoryFilter .TopMainBox .Filter .form-group .InputBox {background-color: #fff;}
.AccountHistoryFilter .TopMainBox .Filter .form-group .InputBox h6 {background-color: #f3f3f3;}
.AccountHistoryFilter .TopMainBox .CommonInputBox {background-color: #fff; min-width: 300px;}
.AccountHistoryFilter .TopMainBox .CommonInputBox .form-control {padding: 20px 10px 20px 15px;}
.AccountHistoryFilter .TopMainBox .UpcomingExportExcel select {border: none; background-color: transparent; margin: 0 20px 0 0; font-size: 16px;}
.AccountHistoryFilter .TopMainBox .UpcomingExportExcel select:focus {outline: none;}
.AccountHistoryFilter .TopMainBox .UpcomingExportExcel .ExportToExcelBtn {padding: 12px 25px; border-radius: 4px; border: 0.5px solid rgba(201, 202, 203, 1); font-family: 'Switzer-Light'; color: #000; font-size: 14px;}
.AccountHistoryFilter .TopMainBox .UpcomingExportExcel .ExportToExcelBtn img {margin: 0 10px 0 0; width: 26px;}
.AccountHistoryFilter .TopMainBox .CommonInputBox h6 {background-color: #f3f3f3;} */

.AccountHistoryFilter .Filter {
    margin: 0 0 50px 0;
}

.AccountHistoryFilter .Filter .ShowHotels {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: #fff;
    font-family: 'Switzer-Medium';
    text-align: center;
    font-size: 16px;
    padding: 12px 30px;
    display: inline-block;
}

.AccountHistoryFilter .Filter {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.AccountHistoryFilter .Filter .form-group {
    width: 100%;
    margin: 0 10px 0 0;
}

.AccountHistoryFilter .Filter .form-group .InputBox.First {
    min-width: 250px;
}

.AccountHistoryFilter .Filter .form-group .InputBox {
    border: 1px solid rgba(121, 116, 126, 1);
    padding: 12px 15px;
    border-radius: 4px;
    position: relative;
    display: block;
}

.AccountHistoryFilter .Filter .form-group .InputBox.SelectBox {
    padding: 0px;
}

.AccountHistoryFilter .Filter .form-group .InputBox .form-control-date {
    width: 100%;
    border: none;
    outline: none;
}

.AccountHistoryFilter .Filter .form-group .InputBox h6 {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    background-color: #fff;
    font-size: 14px;
    font-family: 'Montserrat';
}

.AccountHistoryFilter .Filter .form-group .InputBox .form-control-date,
.AccountHistoryFilter .Filter .form-group .InputBox select {
    border: none;
    outline: none;
    width: 100%;
}

.AccountHistoryFilter .Filter .form-group .InputBox select {
    padding: 13px 15px 12px 40px;
    border-radius: 4px;
}

.AccountHistoryFilter .Filter .form-group .InputBox select {
    appearance: none;
}

.AccountHistoryFilter .Filter .form-group .InputBox .form-control-date:focus {
    outline: none;
}

.AccountHistoryFilter .Filter .form-group .InputBox .FilterIcon {
    position: absolute;
    right: 12px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
}

.AccountHistoryFilter .Filter .form-group .InputBox .StaffIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}



.HotelListingFilter .Filter {
    margin: 0 0 50px 0;
}

.HotelListingFilter .Filter .ShowHotels {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    width: 100%f;
    color: #fff;
    font-family: 'Switzer-Medium';
    text-align: center;
    font-size: 16px;
    padding: 10px 30px;
    display: inline-block;
}

.HotelListingFilter .Filter {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.HotelListingFilter .Filter .form-group {
    width: 100%;
    margin: 0 10px 0 0;
}

.HotelListingFilter .Filter .form-group .InputBox.First {
    min-width: 250px;
}

.HotelListingFilter .Filter .form-group .InputBox {
    border: 1px solid rgba(121, 116, 126, 1);
    padding: 10px 15px;
    border-radius: 4px;
    position: relative;
    display: block;
}

.HotelListingFilter .Filter .form-group .InputBox.SelectBox {
    padding: 0px;
}

.HotelListingFilter .Filter .form-group .InputBox .form-control-date {
    width: 100%;
    border: none;
    outline: none;
}

.HotelListingFilter .Filter .form-group .InputBox h6 {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    background-color: #fff;
    font-size: 14px;
    font-family: 'Switzer-Medium';
}

.HotelListingFilter .Filter .form-group .InputBox .form-control-date,
.HotelListingFilter .Filter .form-group .InputBox select {
    border: none;
    outline: none;
    width: 100%;
}

.HotelListingFilter .Filter .form-group .InputBox select {
    padding: 11px 15px 10px 40px;
    border-radius: 4px;
}

.HotelListingFilter .Filter .form-group .InputBox select {
    appearance: none;
}

.HotelListingFilter .Filter .form-group .InputBox .form-control-date:focus {
    outline: none;
}

.HotelListingFilter .Filter .form-group .InputBox .FilterIcon {
    position: absolute;
    right: 12px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
}

.HotelListingFilter .Filter .form-group .InputBox .StaffIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.packageSearch .SearchBtn {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    height: 44px;
    font-size: 18px;


}


.HotelListingFilter .Filter .SearchBtn {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 44px;
    font-size: 18px;
}

.HotelListingFilter .CommonInputBox.First {
    min-width: 265px;
}

.HotelListingFilter .CommonInputBox .form-control {
    padding: 8px 10px 9px 40px;
    height: auto;
}

.HotelListingFilter .CommonInputBox h6 {
    background-color: #f3f3f3;
}


.ProductFilter .TopMainBox {
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(13, 14, 17, 0.05);
    padding: 25px 20px;
    box-shadow: 0 4px 16px 0px rgba(17, 34, 17, 0.05);
    margin: 25px 0;
    border-radius: 8px;
}

.ProductFilter .TopMainBox .Filter {
    display: flex;
    justify-content: space-between;
}

.ProductFilter .TopMainBox .Filter .form-group {
    margin: 0 10px;
    width: 100%;
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox {
    border: 1px solid rgba(121, 116, 126, 1);
    padding: 11px 15px;
    border-radius: 4px;
    position: relative;
    display: block;
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox.SelectBox {
    padding: 0px;
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox h6 {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    background-color: #fff;
    font-size: 14px;
    font-family: 'Switzer-Medium';
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox .form-control-date,
.ProductFilter .TopMainBox .Filter .form-group .InputBox select {
    border: none;
    outline: none;
    width: 100%;
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox select {
    padding: 12px 15px 12px 40px;
    border-radius: 4px;
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox select {
    appearance: none;
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox .form-control-date:focus {
    outline: none;
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox .form-control-date {
    appearance: none;
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox .FilterIcon {
    position: absolute;
    right: 12px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
}

.ProductFilter .TopMainBox .Filter .form-group .InputBox .StaffIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.ProductFilter .TopMainBox .Filter .SearchBtn {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 46px;
    font-size: 18px;
}

.ProductFilter .TopMainBox .CommonInputBox .form-control {
    padding: 9px 10px 9px 40px;
    height: auto;
}

.ProductFilter .TopMainBox .CommonInputBox h6 {
    background-color: #fff;
}

.ProductFilter .TopMainBox .CommonInputBox.First {
    min-width: 250px;
}


.EnterCustomerDetailsHeading h4 {
    font-family: 'Switzer-bold';
    font-size: 20px;
    margin: 0 0 25px 0;
}

.EnterCustomerDetails.BottomPadding {
    padding-bottom: 10px;
}

.EnterCustomerDetails {
    background-color: #fff;
    box-shadow: 0px 4px 16px 0 rgba(17, 34, 17, 0.05);
    border-radius: 12px;
    padding: 30px 25px 40px;
    margin: 0 0 25px 0;
}

.EnterCustomerDetails.GstNumber .form-group {
    display: flex;
}

.EnterCustomerDetails.GstNumber .form-group .BrandBtn {
    margin: 0 0 0 10px;
    padding: 16px 30px;
    min-width: 200px;
}

.EnterCustomerDetails h4 {
    color: rgba(0, 0, 0, 1);
    margin: 0 0 15px 0;
    font-family: 'Switzer-Semibold';
    font-size: 20px;
}

.EnterCustomerDetails h6 {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    margin: 0 0 20px 0;
}

.EnterCustomerDetails h5 {
    font-family: 'Switzer-Medium';
    font-size: 16px;
    color: rgba(17, 34, 17, 1);
    margin: 0 0 25px 0;
}

.EnterCustomerDetails form .form-group .form-control {
    border: 1px solid rgba(121, 116, 126, 1);
    border-radius: 4px;
    padding: 1.75rem 0.75rem;
}

.EnterCustomerDetails form .form-group .form-control::placeholder {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
}

.EnterCustomerDetails form .form-group .form-control:focus {
    box-shadow: none;
}

.EnterCustomerDetails form .form-group .form-control.FlightNumber {
    min-width: 140px;
}

.EnterCustomerDetails form p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.EnterCustomerDetails form .ForName {
    display: flex;
}

.EnterCustomerDetails form .NameSelectBox {
    border: 1px solid rgba(121, 116, 126, 1);
    border-radius: 4px;
    position: relative;
    min-width: 100px;
    margin: 0 10px 0 0;
}

.EnterCustomerDetails form .NameSelectBox span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.EnterCustomerDetails form .NameSelectBox select {
    padding: 1.1rem 0.75rem;
    border-radius: 4px;
    border: none;
    appearance: none;
    width: 100%;
}

.EnterCustomerDetails form .NameSelectBox select:focus {
    outline: none;
}

.EnterCustomerDetails .CheckBox .checkmark {
    border-radius: 0px;
}

.EnterCustomerDetails .Filter {
    display: flex;
    justify-content: space-between;
}

.EnterCustomerDetails .Filter:first-child {
    margin: 0 0 20px 0;
}

.EnterCustomerDetails .Filter .form-group {
    margin: 0 10px;
}

.EnterCustomerDetails .Filter .form-group:first-child {
    margin-left: 0px;
}

.EnterCustomerDetails .Filter .form-group:last-child {
    margin-right: 0px;
}

.BookingHotelDetailsArea {
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(17, 34, 17, 0.05);
    padding: 25px 20px 50px;
    border-radius: 12px;
    margin: 0 0 25px 0;
}

.BookingHotelDetailsArea .HotelNameRatings {
    border: 0.5px solid rgba(141, 211, 187, 1);
    border-radius: 8px;
    position: relative;
    padding: 25px 0 25px 110px;
    margin: 0 0 50px 0;
}

.BookingHotelDetailsArea .HotelNameRatings.Transfer {
    padding: 25px 0 25px 135px;
}

.BookingHotelDetailsArea .HotelNameRatings figure {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
}

.BookingHotelDetailsArea .HotelNameRatings h3 {
    font-family: 'Switzer-Semibold';
    font-size: 24px;
}

.BookingHotelDetailsArea .HotelNameRatings p {
    color: rgba(102, 112, 133, 1);
    margin: 12px 0;
    font-size: 16px;
}

.BookingHotelDetailsArea .HotelNameRatings p img {
    margin: 0 5px 0 0;
}

.BookingHotelDetailsArea .RemarksBox h4 {
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-Semibold';
    margin: 0 0 15px 0;
    font-size: 24px;
}

.BookingHotelDetailsArea .RemarksBox p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    margin: 0 0 15px 0;
}

.BookingHotelDetailsArea .RequestAccommodationBox h5,
.BookingHotelDetailsArea .RateInformationBox h5 {
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-Medium';
    margin: 0 0 15px 0;
    font-size: 20px;
}

.BookingHotelDetailsArea .RequestAccommodationBox p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    margin: 0 0 20px 0;
}

.BookingHotelDetailsArea .RecommendationBox {
    display: flex;
    margin: 0 0 15px 0;
}

.BookingHotelDetailsArea aside {
    width: 50%;
}

.BookingHotelDetailsArea .form-control {
    border: 1px solid rgba(121, 116, 126, 1);
    border-radius: 4px;
    padding: 1rem 0.75rem;
}

.BookingHotelDetailsArea .form-control:focus {
    box-shadow: none;
}

.BookingHotelDetailsArea .form-control::placeholder {
    color: rgba(51, 51, 51, 1);
    font-size: 16px;
}

.BookingHotelDetailsArea .RateInformationBox p {
    color: rgba(102, 112, 133, 1);
    font-size: 18px;
    margin: 0 0 20px 0;
    font-family: 'Switzer-Medium';
}

.BookingHotelDetailsArea .RateInformationBox p:last-child {
    font-family: 'Switzer-Regular';
}

.BookingHotelDetailsArea .RateInformationBox .Address p {
    color: rgba(17, 34, 17, 1);
    font-size: 18px;
    margin: 0 0 20px 0;
    font-family: 'Switzer-Regular';
}

.BookingHotelDetailsArea .RateInformationBox .Address p span {
    color: rgba(0, 174, 0, 1);
    font-size: 18px;
    font-family: 'Switzer-bold';
}

.BookingHotelDetailsArea .HotelNamesBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
}

.BookingHotelDetailsArea .HotelNamesBox .HnbLeft {
    position: relative;
    padding: 0 0 0 60px;
}

.BookingHotelDetailsArea .HotelNamesBox .HnbLeft figure {
    position: absolute;
    left: 0;
}

.BookingHotelDetailsArea .HotelNamesBox .HnbLeft h5 {
    font-size: 18px;
    font-family: 'Switzer-Medium';
}

.BookingHotelDetailsArea .HotelNamesBox .HnbLeft ul {
    display: flex;
    margin: 10px 0 0 0;
}

.BookingHotelDetailsArea .HotelNamesBox .HnbLeft ul li {
    font-size: 13px;
    font-family: 'Switzer-Light';
}

.BookingHotelDetailsArea .HotelNamesBox .HnbLeft ul li:first-child {
    margin: 0 10px 0 0;
}

.BookingHotelDetailsArea .HotelNamesBox .HnbLeft ul li span {
    margin: 0 10px 0 0;
}

.BookingHotelDetailsArea .HotelNamesBox .HnbRight h6 {
    color: rgba(17, 34, 17, 1);
    font-family: 'Switzer-Semibold';
}

.BookingHotelDetailsArea .BookingHotelChecks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px 0;
}

.BookingHotelDetailsArea .BookingHotelChecks h5 {
    color: rgba(17, 34, 17, 1);
    margin: 0 0 10px 0;
    font-size: 20px;
    font-family: 'Switzer-Semibold';
}

.BookingHotelDetailsArea .BookingHotelChecks p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
}

.BookingHotelDetailsArea .HotelNameRatings .Heading {
    font-size: 18px;
    font-family: 'Switzer-bold';
    color: rgba(17, 34, 17, 1);
}

.BookingHotelDetailsArea h2 {
    color: rgba(17, 34, 17, 1);
    font-family: 'Switzer-bold';
    font-size: 24px;
    margin: 0 0 25px 0;
}

.BookingHotelDetailsArea h5 {
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-Regular';
    font-size: 15px;
}

.BookingHotelDetailsArea h5 img {
    margin: 0 15px 0 0;
}

.BookingHotelDetailsArea h5 span {
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-Medium';
    font-size: 15px;
}


.PriceBookingDetails {
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(17, 34, 17, 0.05);
    border-radius: 12px;
    padding: 25px;
    margin: 0 0 25px 0;
}

.PriceBookingDetails .EmptyCartBox {
    background-color: rgba(236, 30, 50, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.PriceBookingDetails .EmptyCartBox p {
    color: #fff;
    font-family: 'Switzer-Light';
}

.PriceBookingDetails .EmptyCartBox p img {
    margin: 0 10px 0 0;
}

.PriceBookingDetails .EmptyCartBox p span {
    font-family: 'Switzer-Semibold';
}

.PriceBookingDetails .EmptyCartBox .EmptyCart {
    border: 0.5px solid rgba(201, 202, 203, 1);
    border-radius: 4px;
    padding: 8px 40px;
    display: inline-block;
    color: #fff;
    font-size: 12px;
    font-family: 'Switzer-Light';
}

.PriceBookingDetails thead tr:first-child {
    border-top: 0.5px solid rgba(17, 34, 17, 0.25);
}

.PriceBookingDetails thead tr th {
    color: rgba(17, 34, 17, 1);
    padding: 10px 0 10px 0;
    font-size: 20px;
    font-family: 'Switzer-bold';
}

.PriceBookingDetails tbody tr td {
    color: rgba(17, 34, 17, 1);
    padding: 10px 0 10px 0;
    font-size: 16px;
    font-family: 'Switzer-Medium';
}

.PriceBookingDetails tbody tr td:last-child {
    color: rgba(17, 34, 17, 1);
    padding: 10px 0 10px 0;
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    text-align: right;
}

.PriceBookingDetails tfoot tr td {
    color: rgba(17, 34, 17, 1);
    padding: 10px 0 10px 0;
    font-size: 20px;
    font-family: 'Switzer-Medium';
}

.PriceBookingDetails tfoot tr {
    border: 0.5px solid rgba(17, 34, 17, 0.25);
    border-left: none;
    border-right: none;
}

.PriceBookingDetails tfoot tr td:last-child {
    color: rgba(17, 34, 17, 1);
    padding: 5px 0 5px 0;
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    text-align: right;
}

.DepartingFlightArea .PbdHotelName {
    border-bottom: none !important;
    padding: 18px 0 0px !important;
}

.DepartingFlightArea .PbdHotelName,
.PriceBookingDetails .PbdHotelName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid rgba(17, 34, 17, 0.25);
    padding: 18px 0;
}

.PriceBookingDetails .PbdHotelName.WalletBox small {
    color: rgba(236, 30, 50, 1);
}

.DepartingFlightArea .PbdHotelName h6,
.PriceBookingDetails .PbdHotelName h6 {
    color: rgba(17, 34, 17, 1);
    font-size: 16px;
    font-family: 'Switzer-Medium';
}

.DepartingFlightArea .PbdHotelName h5,
.PriceBookingDetails .PbdHotelName h5 {
    color: rgba(17, 34, 17, 1);
    font-size: 16px;
    font-family: 'Switzer-Semibold';
}

.DepartingFlightArea .PbdHotelName h5 span,
.PriceBookingDetails .PbdHotelName h5 span {
    color: rgba(236, 30, 50, 1);
    font-size: 13px;
    font-family: 'Switzer-Regular';
    margin: 0 0 0 5px;
    text-decoration: underline;
    cursor: pointer;
}

.PriceBookingDetails .PbdHotelName h6 span {
    margin: 0 10px 0 0;
}

.PriceBookingDetails .CheckBox {
    font-size: 17px;
    margin: 25px 0;
}

.PriceBookingDetails .CheckBox strong {
    text-decoration: underline;
}

.ConfirmBookigBtn {
    background-color: rgba(236, 30, 50, 1);
    border-radius: 4px;
    padding: 15px 0px;
    display: inline-block;
    width: 100%;
    color: #fff;
    font-family: 'Switzer-Medium';
    font-size: 15px;
    text-align: center;
}

.ConfirmBookigBtn:hover {
    color: #fff;
}




/* Account History page css start  */
.TicketsBookingsTabs h2 {
    font-family: 'Switzer-bold';
    font-size: 32px;
    margin: 0 0 20px 0;
}

.TicketsBookingsTabs .nav-tabs {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 16px 0 rgba(17, 34, 17, 0.05);
    border-bottom: none;
    padding: 10px 20px 0;
    display: flex;
    justify-content: space-between;
}

.TicketsBookingsTabs .nav-tabs .nav-item {
    flex: 1;
}

.TicketsBookingsTabs .nav-tabs .nav-item .nav-link {
    position: relative;
    padding: 25px 0 25px 70px;
    border: none;
}

.TicketsBookingsTabs .nav-tabs .nav-item .nav-link.active {
    border-bottom: 4px solid rgba(3, 42, 82, 1);
}

.TicketsBookingsTabs .nav-tabs .nav-item .nav-link .TicketsBookingsOverlay {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
}

.TicketsBookingsTabs .nav-tabs .nav-item .nav-link h6 {
    font-size: 16px;
    color: #000;
    font-family: 'Switzer-Semibold';
}

.TicketsBookingsTabs .nav-tabs .nav-item .nav-link p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.40);
}

.AccountHistoryBox {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    margin: 0 0 25px 0;
}

.AccountHistoryBox .AhbTop {
    display: flex;
    justify-content: space-between;
    padding: 25px 100px 25px 35px;
    border-bottom: 0.5px solid rgba(201, 202, 203, 1);
}

.AccountHistoryBox .AhbTop .AhbtSmall p {
    color: #000;
    font-size: 14px;
    margin: 0 0 7px 0;
    font-family: 'Switzer-Light';
}

.AccountHistoryBox .AhbTop .AhbtSmall h5 {
    color: #000;
    font-size: 16px;
}

.AccountHistoryBox .AhbTop .AhbtSmall h5 span {
    color: rgba(3, 42, 82, 1);
}

.AccountHistoryBox .AhbTop .AhbtSmall h5 span img {
    margin: 0 15px 0 10px;
}

.AccountHistoryBox .AhbTop .AhbtSmall .Confirmed {
    color: rgba(0, 204, 97, 1);
    display: flex;
    align-items: center;
}

.AccountHistoryBox .AhbTop .AhbtSmall .Cancelled {
    color: rgba(236, 30, 50, 1);
    display: flex;
    align-items: center;
}

.AccountHistoryBox .AhbBottom {
    padding: 25px 35px 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.AccountHistoryBox .AhbBottom .AhbBottomStart {
    display: flex;
    width: 55%;
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft {
    position: relative;
    width: 350px;
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft.Transfer {
    width: 200px;
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft .ProductLeftOverlay {
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft .ProductLeftOverlay .PloImages {
    display: flex;
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft .ProductLeftOverlay p {
    font-size: 16px;
    color: #fff;
    margin: 5px 0 0 0;
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft figure {
    border-radius: 10px;
    margin-bottom: 0px !important;
    position: relative;
    margin-left: -8px;
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft figure img {
    width: 100%;
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft figure:before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    background-image: linear-gradient(rgba(51, 51, 51, 0), rgba(51, 51, 51, 1));
    content: "";
}

.AccountHistoryBox .AhbBottom .AhbBottomStartLeft.Transfer figure:before {
    display: none;
}

.AhbBottomStartRight .AhbBottomStartRightTop {
    position: relative;
    padding-bottom: 10px;
}

.AhbBottomStartRight .AhbBottomStartRightTop .ProductRightAccountOverlay {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
}

.AhbBottomStartRight .AhbBottomStartRightTop .ProductRightAccountOverlay span {
    border-radius: 10px;
    border: 0.5px solid rgba(229, 231, 235, 1);
    width: 40px;
    height: 42px;
    margin: 0 10px 0 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.AhbBottomStartRight .AhbBottomStartRightTop .ProductRightAccountOverlay a {
    display: inline-block;
    background-color: rgba(236, 30, 50, 1);
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;
}

.AhbBottomStartRight .AhbBottomStartRightTop .ProductRightAccountOverlay a i {
    margin: 0 10px 0 0;
}

.AhbBottomStartRight .AhbBottomStartRightTop h6 {
    font-family: 'Switzer-Medium';
    color: #000;
    font-size: 20px;
    margin: 0 0 5px 0;
}

.AhbBottomStartRight .AhbBottomStartRightTop h6 span {
    color: rgba(102, 112, 133, 1);
    font-size: 18px;
    font-family: 'Switzer-Light';
    margin: 0 0 0 5px;
}

.AhbBottomStartRight .AhbBottomStartRightTop p {
    font-family: 'Switzer-Regular';
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    margin: 0 0 5px 0;
}

.AhbBottomStartRight .AhbBottomStartRightTop p i {
    margin: 0 10px 0 0;
}

.AhbBottomStartRight .AhbBottomStartRightTop ul {
    display: flex;
    margin: 0 0 15px 0;
}

.AhbBottomStartRight .AhbBottomStartRightTop ul li {
    margin: 0 15px 0 0;
}

.AhbBottomStartRight .AhbBottomStartRightTop ul li a {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    margin: 0 15px 0 0;
}

.AhbBottomStartRight .AhbBottomStartRightTop ul li a span {
    font-family: 'Switzer-Semibold';
    font-size: 15px;
}

.AhbBottomStartRight .AhbBottomStartRightTop ul li a img {
    margin: 0 10px 0 0;
}

.AhbBottomStartRight .AhbBottomStartRightTop ul li a i {
    color: rgba(252, 211, 77, 1);
    margin: 0 10px 0 0;
    font-size: 14px;
}

.AhbBottomStartRight .AhbBottomStartRightTop .HotelFacility a {
    color: rgba(3, 42, 82, 1);
    font-weight: 500;
    display: inline-block;
    font-family: 'Switzer-Medium';
    background-color: rgba(234, 240, 255, 1);
    font-size: 12px;
    padding: 10px;
    margin-right: 5px;
    border-radius: 5px;
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight .AhbBottomStartRightCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight .AhbBottomStartRightCenter .SmallBox h6 {
    color: rgba(17, 34, 17, 1);
    margin: 0 0 5px 0;
    font-family: 'Switzer-Semibold';
    font-size: 12px;
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight .AhbBottomStartRightCenter .SmallBox p {
    color: rgba(102, 112, 133, 1);
    font-size: 13px;
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight {
    margin: 0 0 0 20px;
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight .AhbBottomStartRightBottom p {
    color: #000;
    font-family: 'Switzer-light';
    font-size: 14px;
    margin: 0 0 10px 0;
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight .AhbBottomStartRightBottom p span {
    color: rgba(3, 42, 82, 1);
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight .AhbBottomStartRightBottom p:last-child {
    font-family: 'Switzer-Light';
    margin: 0 0 0 0;
}

.AhbBottom .AhbBottomStart .AhbBottomStartRight .AhbBottomStartRightBottom p:last-child span {
    font-family: 'Switzer-Medium';
    color: #000;
}



.AccountHistoryBox .AhbBottom .AhbBottomCenter {
    display: flex;
    flex-direction: column;
    width: 20%;
}

.AccountHistoryBox .AhbBottom .AhbBottomCenter p {
    font-size: 14px;
    font-family: 'Switzer-Light';
    margin: 0 0 15px 0;
}

.AccountHistoryBox .AhbBottom .AhbBottomCenter h6 {
    font-size: 14px;
}

.AccountHistoryBox .AhbBottom .AhbBottomEnd {
    display: flex;
    width: 25%;
}

.AccountHistoryBox .AhbBottom .AhbBottomEnd {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.BrandBtn {
    background-color: rgba(236, 30, 50, 1);
    width: 175px;
    text-align: center;
    margin: 0 0 10px 0;
    border: 0.5px solid rgba(236, 30, 50, 1);
    border-radius: 4px;
    padding: 13px 30px;
    color: #fff;
    font-family: 'Switzer-Semibold';
    display: inline-block;
    font-size: 16px;
}

.BrandBtn:hover {
    color: #fff;
}

.BrandOutlineBtn {
    border: 0.5px solid rgba(201, 202, 203, 1);
    width: 175px;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    padding: 13px 30px;
    color: #000;
    font-family: 'Switzer-Light';
    display: inline-block;
    font-size: 14px;
}

.BrandOutlineBtn:hover {
    color: #000;
}

.AccountHistoryBox .AhbBottom .AhbBottomEnd p {
    font-family: 'Switzer-Light';
    font-size: 14px;
    color: #000;
    margin: 20px 0 0 0;
}

.AccountHistoryBox .AhbBottom .AhbBottomEnd h3 {
    font-family: 'Switzer-bold';
    font-size: 24px;
}

.AccountHistoryBox .AhbBottom .AhbBottomEnd h3 span {
    font-size: 12px;
}







/* Account History page css end  */


/* HotelListing page css start  */
.breadcrumb {
    background-color: transparent;
    padding: 0 0 20px;
}

.breadcrumb ul {
    display: flex;
}

.breadcrumb ul li a {
    color: rgba(236, 30, 50, 1);
    margin: 0 10px 0 0;
    position: relative;
    font-family: 'Switzer-Medium';
}

.breadcrumb ul li {
    color: rgba(17, 34, 17, 0.75);
}

.breadcrumb ul li a.active {
    color: rgba(17, 34, 17, 0.75);
}

.breadcrumb ul li a span {
    margin: 0 0 0 5px;
}

/* .breadcrumb ul li a::after {content: ""; font-family: ''; position: absolute; right: -20px; top: 50%; transform: translateY(-50%); color: rgba(17, 34, 17, 1); } */

.HotelBannerArea {
    padding: 35px 0;
}

.HotelBannerArea figure img {
    width: 100%;
}

.HotelNameInformation {
    display: flex;
    justify-content: space-between;
}

.BookingHotelDetailsArea .HotelNameRatings {
    border: 0.5px solid rgba(141, 211, 187, 1);
    border-radius: 8px;
    position: relative;
    padding: 25px 0 25px 110px;
    margin: 0 0 50px 0;
}

.HotelNameInformation .HotelNameInformationLeft h3 {
    font-family: 'Switzer-Semibold';
    font-size: 24px;
}

.HotelNameInformation .HotelNameInformationLeft p {
    color: rgba(102, 112, 133, 1);
    margin: 12px 0;
    font-size: 16px;
}

.HotelNameInformation .HotelNameInformationLeft p img {
    margin: 0 5px 0 0;
}


.HotelNameInformation .HotelNameInformationRight h6 span {
    font-size: 32px;
    font-family: 'Switzer-bold';
    margin: 0 0 0 10px;
}

.HotelNameInformation .HotelNameInformationRight .Icons {
    display: flex;
    align-items: center;
    margin: 15px 0 0 0;
    justify-content: flex-end;
}

.HotelNameInformation .HotelNameInformationRight .Icons a.Icon {
    border-radius: 4px;
    border: 1px solid rgba(236, 30, 50, 1);
    width: 49px;
    height: 49px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 10px 0 0;
}

.HotelNameInformation .HotelNameInformationRight .Icons .AddToCartBtn {
    padding: 14px 20px;
}

.HotelListingFilter {
    padding: 0 0 40px 0;
}

.HotelListingFilter .TopMainBox {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
}

.HotelListingFilter .TopMainBox .Filter .form-group .InputBox h6 {
    background-color: #f3f3f3;
}

.HotelListingFilter .TopMainBox .Filter .form-group .InputBox .form-control-date {
    background-color: #f3f3f3;
}

.HotelListingFilter .TopMainBox .Filter .form-group .InputBox select {
    background-color: #f3f3f3;
}


.OverviewArea {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
    padding: 0 0 50px 0;
    margin: 0 0 75px 0;
}

.OverviewArea h4 {
    font-family: 'Switzer-Semibold';
    font-size: 22px;
    margin: 0 0 20px 0;
}

.OverviewArea p {
    font-family: 'Switzer-Medium';
    font-size: 16px;
    margin: 0 0 35px 0;
    color: rgba(102, 112, 133, 1);
}

.OverviewArea ul {
    display: flex;
}

.OverviewArea ul li {
    margin: 0 15px 0 0;
    border: 1px solid rgba(3, 42, 82, 1);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    padding: 15px 0 15px 20px;
    height: 145px;
    width: 160px;
}

.OverviewArea ul li.First {
    justify-content: flex-end;
}

.OverviewArea ul li p {
    margin: 0;
    font-size: 14px;
    font-family: 'Switzer-Medium';
    color: rgba(3, 42, 82, 1);
}

.OverviewArea ul li h1 {
    position: absolute;
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-bold';
    top: 15px;
    left: 20px;
}

.OverviewArea ul li h6 {
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
}

.OverviewArea ul li h5 {
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-bold';
    font-size: 16px;
    margin: 0 0 5px 0;
}

.OverviewArea ul li:hover {
    background-color: rgba(3, 42, 82, 1);
}

.OverviewArea ul li:hover h6,
.OverviewArea ul li:hover h5,
.OverviewArea ul li:hover p,
.OverviewArea ul li:hover h1 {
    color: #fff;
}

.OverviewArea ul li:hover span {
    filter: brightness(0) invert(1);
}




.AvailableRoomsArea {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
    padding: 0 0 50px 0;
    margin: 0 0 75px 0;
}

.AvailableRoomsArea h4 {
    font-family: 'Switzer-Semibold';
    font-size: 24px;
    margin: 0 0 20px 0;
}

.AvailableRoomsArea .AvailableRoomsBox {
    position: relative;
    padding: 20px 0 15px 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
}

.AvailableRoomsArea .AvailableRoomsBox:last-child {
    border-bottom: none;
}

.AvailableRoomsArea .AvailableRoomsBox figure {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.AvailableRoomsArea .AvailableRoomsBox .AvailableRoomsBoxLeft {
    font-size: 18px;
    font-family: 'Switzer-Medium';
}

.AvailableRoomsArea .AvailableRoomsBox .AvailableRoomsBoxLeft ul {
    display: flex;
    margin: 10px 0 0 0;
}

.AvailableRoomsArea .AvailableRoomsBox .AvailableRoomsBoxLeft ul li {
    font-size: 13px;
    font-family: 'Switzer-Light';
}

.AvailableRoomsArea .AvailableRoomsBox .AvailableRoomsBoxLeft ul li:first-child {
    margin: 0 10px 0 0;
}

.AvailableRoomsArea .AvailableRoomsBox .AvailableRoomsBoxLeft ul li span {
    margin: 0 10px 0 0;
}

.AvailableRoomsArea .AvailableRoomsBox .AvailableRoomsBoxRight {
    display: flex;
    align-items: center;
}

.AvailableRoomsArea .AvailableRoomsBox .AvailableRoomsBoxRight h5 {
    font-size: 24px;
    font-family: 'Switzer-Semibold';
    margin: 0 50px 0 0;
}

.AvailableRoomsArea .AvailableRoomsBox .AvailableRoomsBoxRight h5 span {
    font-size: 14px;
    font-family: 'Switzer-Semibold';
}

.HotelListingPage section {
    background-color: transparent;
}

.FilterProductsArea section {
    background-color: transparent;
}

.FlightListingArea section {
    background-color: transparent;
}

.LocationMapArea {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
    padding: 0 0 50px 0;
    margin: 0 0 75px 0;
}

.LocationMapArea .LocationMapTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 25px 0;
}

.LocationMapArea .LocationMapTop h4 {
    font-family: 'Switzer-Semibold';
    font-size: 24px;
}

.LocationMapArea iframe {
    border-radius: 16px;
}

.LocationMapArea p {
    font-family: 'Switzer-Medium';
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
}

.LocationMapArea p img {
    margin: 0 5px 0 0;
}

.AmenitiesArea {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
    padding: 0 0 50px 0;
    margin: 0 0 75px 0;
}

.AmenitiesArea h4 {
    font-family: 'Switzer-Semibold';
    font-size: 24px;
    margin: 0 0 20px 0;
}

.AmenitiesArea .AmenitiesBody {
    display: flex;
}

.AmenitiesArea .AmenitiesBody aside {
    width: 100%;
}

.AmenitiesArea .AmenitiesBody aside ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.AmenitiesArea .AmenitiesBody aside ul li {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-family: 'Switzer-Medium';
    width: 45%;
}

.AmenitiesArea .AmenitiesBody aside ul li span {
    margin: 0 15px 0 0;
}

.AmenitiesArea .AmenitiesBody aside ul li a {
    font-size: 14px;
    color: rgba(243, 4, 44, 1);
    font-family: 'Switzer-Semibold';
}

.ReviewsArea {
    padding: 0;
    margin: 0 0 75px 0;
}

.ReviewsArea .ReviewsAreaTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 35px 0;
}

.ReviewsArea .ReviewsAreaTop h4 {
    font-family: 'Switzer-Semibold';
    font-size: 24px;
}

.ReviewsArea .ReviewBox {
    position: relative;
    padding: 0 50px 25px 75px;
    margin: 0 0 25px 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
}

.ReviewsArea .ReviewBox:last-child {
    margin: 0px;
}

.ReviewsArea .ReviewBox figure {
    position: absolute;
    left: 0;
    top: 0;
}

.ReviewsArea .ReviewBox i {
    position: absolute;
    right: 0;
    top: 0;
}

.ReviewsArea .ReviewBox h6 {
    color: #000;
    font-family: 'Switzer-Semibold';
    font-size: 16px;
    margin: 0 0 10px 0;
}

.ReviewsArea .ReviewBox h6 span {
    color: #000;
    font-family: 'Switzer-Regular';
    font-size: 16px;
    margin: 0 0 0 25px;
    position: relative;
}

.ReviewsArea .ReviewBox h6 span::before {
    content: '|';
    position: absolute;
    left: -13px;
    color: #000;
}

.ReviewsArea .ReviewBox p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
}

.ReviewsArea .ReviewBox.First {
    padding-left: 0;
    display: flex;
    align-items: center;
}

.ReviewsArea .ReviewBox.First h1 {
    font-family: 'Switzer-bold';
    font-size: 50px;
    margin: 0 10px 0 0;
}

.ReviewsArea .ReviewBox.First h3 {
    font-size: 20px;
    margin: 0 0 5px 0;
    font-family: 'Switzer-Semibold';
}

.ReviewsArea .ReviewBox.First p {
    font-size: 16px;
    color: #000;
}

.Pagination {
    text-align: center;
}

.Pagination h6 i {
    margin: 0 20px;
}

.pagination {
    justify-content: flex-end;
}

/* HotelListing page css end  */



.SmallModal .modal-dialog {
    min-width: 588px;
}

.SmallModal .modal-dialog .modal-body {
    padding: 0;
}

.MediumModal .modal-dialog {
    min-width: 788px;
}

.MediumModal .modal-dialog .modal-body {
    padding: 0;
}

.LargeModal .modal-dialog {
    min-width: 1075px;
}

.LargeModal .modal-dialog .modal-body {
    padding: 0;
}


.SmallModal .modal-content,
.MediumModal .modal-content,
.LargeModal .modal-content {
    border-radius: 12px;
}

.ModalBox .modal-header {
    background-color: rgba(2, 25, 49, 1);
    border-radius: 12px 12px 0 0;
    padding: 25px;
}

.ModalBox .modal-header h5 {
    color: #fff;
    font-size: 24px;
    font-family: 'Switzer-Semibold';
}

.ModalBox .modal-header h5 span {
    width: 36px;
    height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin: 0 15px 0 0;
    border-radius: 50%;
}

.ModalBox .modal-header h5 span i {
    color: rgba(2, 25, 49, 1);
}


.ModalContent {
    padding: 40px 50px;
    background-color: #fff;
}

.ModalContent .BookingSuccessArea {
    padding: 0 0 40px;
}

.ModalContent .BookingSuccessArea h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    font-family: 'Switzer-Medium';
    text-align: center;
}

.ModalContent .BookingSuccessArea p {
    font-size: 16px;
    text-align: center;
    font-family: 'Switzer-Regular';
}

.ModalContent .BookingSuccessArea p span {
    font-family: 'Switzer-Medium';
}

.ModalContent .BookingSuccessArea .BrandBtn {
    margin: 35px 0 15px 0;
}

.ModalContent .BookingSuccessArea .BrandOutlineBtn {
    margin: 0px 0 20px 0;
}

.ModalContent .BookingSuccessArea .BrandBtn,
.ModalContent .BookingSuccessArea .BrandOutlineBtn {
    width: 100%;
}

.ModalContent .modal-footer {
    background-color: rgba(238, 237, 237, 1);
    padding: 20px 35px 25px;
    border-radius: 0 0 12px 12px;
    margin: 0 -50px -40px;
}

.ModalContent .modal-footer a {
    background-color: rgba(236, 30, 50, 1);
    border-radius: 4px;
    padding: 15px 0;
    text-align: center;
    display: inline-block;
    width: 100%;
    color: #fff;
}

.ModalContent .modal-footer p {
    color: rgba(17, 34, 17, 1);
    font-size: 13px;
    margin: 10px 0 0 0;
    text-align: center;
}



.CountriesArea .Heading {
    margin: 0 0 25px 0;
    border-bottom: 0.5px solid rgba(200, 200, 200, 1);
    padding: 0px 0 15px;
}

.CountriesArea h3 {
    font-size: 20px;
    font-family: 'Switzer-bold';
    color: rgba(0, 0, 0, 1);
}

.CountriesArea ul {
    border-bottom: 0.5px solid rgba(200, 200, 200, 1);
}

.CountriesArea ul li {
    font-family: 'Switzer-Medium';
    margin: 0 0 10px 0;
    color: rgba(0, 0, 0, 1);
}

.CountriesArea .form-control {
    border: none;
    border-bottom: 0.5px solid rgba(200, 200, 200, 1);
    border-radius: 0px;
    padding: 30px 0;
}

.CountriesArea .form-control::placeholder {
    font-family: 'Switzer-bold';
    font-size: 20px;
    color: rgba(0, 0, 0, 0.20);
}




/* Flight Listing page css Start  */

.FlightListingArea {
    padding: 75px 100px;
    background-color: #fAfBfC;
    position: relative;
}


.BookNowBoxTop {
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(17, 34, 17, 0.05);
    margin: 0 0 25px 0;
    padding: 20px 25px;
}

.BookNowBoxTop h6 {
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 10px 0;
}

.BookNowBoxTop p {
    font-size: 14px;
    color: rgba(17, 34, 17, 0.40);
}

.BookNowBox {
    box-shadow: 0 4px 16px 0 rgba(17, 34, 17, 0.05);
    background-color: #fff;
    position: relative;
    padding: 25px 35px 0px 150px;
    border-radius: 12px;
    margin: 0 0 25px 0;
}

.BookNowBox figure {
    position: absolute;
    left: 15px;
    top: 25px;
    width: 104px;
    margin: 0px;
}

.BookNowBox.Modal {
    margin-bottom: 0px;
    box-shadow: none;
}

.BookNowBox.Modal figure {
    margin: 0 0 15px 0;
    text-align: center;
}

.BookNowBox.Modal figure figcaption h5 {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-family: 'Switzer-Medium';
    margin: 20px 0 5px 0;
}

.BookNowBox.Modal figure figcaption p {
    color: rgba(17, 34, 17, 1);
    font-size: 13px;
    font-family: 'Switzer-Regular';
}

.BookNowBox .BookNowBoxDetails .BnbdTop {
    border-bottom: 0.5px solid rgba(17, 34, 17, 0.25);
    display: flex;
    justify-content: space-between;
}

.BookNowBox .BookNowBoxDetails .BnbdTop.Modal {
    align-items: center;
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall {
    text-align: center;
    padding: 0 0 15px 0;
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall h6 {
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 5px 0;
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall p {
    font-size: 14px;
    color: rgba(17, 34, 17, 0.40);
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall .SeatLeft {
    color: rgba(243, 4, 44, 1);
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall .SeatLeft span {
    margin: 0 5px 0 0;
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall.NonStop h6 {
    font-size: 14px;
    font-family: 'Switzer-Semibold';
    color: rgba(17, 34, 17, 0.78);
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall.NonStop p {
    font-size: 14px;
    color: rgba(17, 34, 17, 1);
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall.Economy h6 {
    color: #000;
    font-family: 'Switzer-Regular';
    margin: 0 0 20px 0;
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall.Economy .BrandBtn {
    padding: 10px 30px;
    margin: 0px;
}

.BookNowBox .BookNowBoxDetails .BnbdBottom {
    padding: 20px 0 20px 25px;
    display: flex;
    justify-content: space-between;
}

.DepartingFlightArea ul {
    border-bottom: 0.5px solid rgba(17, 34, 17, 0.25);
    padding: 0 0 15px 0;
    margin: 0 0 25px 0;
}

.DepartingFlightArea ul,
.BookNowBox .BookNowBoxDetails .BnbdBottom ul {
    display: flex;
    align-items: center;
}

.DepartingFlightArea ul li,
.BookNowBox .BookNowBoxDetails .BnbdBottom ul li {
    margin: 0 15px 0 0;
}

.DepartingFlightArea ul li span,
.BookNowBox .BookNowBoxDetails .BnbdBottom ul li span {
    margin: 0 10px 0 0;
}

.BookNowBox .BookNowBoxDetails .BnbdBottom h6 span {
    font-size: 24px;
    font-family: 'Switzer-bold';
}

.BookNowBox .BookNowBoxDetails .BnbdBottom h6 {
    font-size: 12px;
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall h3 {
    font-size: 20px;
    color: rgba(17, 34, 17, 1);
    font-family: 'Switzer-Semibold';
}

.BookNowBox .BookNowBoxDetails .BnbdBottom .Refundable {
    color: rgba(95, 167, 53, 1);
    font-size: 16px;
}

.BookNowBox .BookNowBoxDetails .BnbdTop .BnbdTopSmall.Modal h6 {
    font-size: 14px;
    color: rgba(17, 34, 17, 1);
    font-family: 'Switzer-Regular';
}




.Radio {
    color: #000;
}

.Radio {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 14px;
    line-height: 16px;
    user-select: none;
    margin: 0px !important;
    font-weight: 500;
}

.Radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.Radio .checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 14px;
    width: 14px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid rgb(0, 0, 0);
}

.Radio:hover input~.checkmark {
    border-color: rgb(0, 0, 0);
}

.Radio input:checked~.checkmark {
    border-color: rgba(236, 30, 50, 1);
}

.Radio .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

.Radio input:checked~.checkmark::after {
    display: block;
}

.Radio .checkmark::after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(236, 30, 50, 1);
    inset: 0px;
    margin: auto;
}

.RadioText {
    display: none;
    transition: all 0.5s ease-in-out 0s;
}

.FlightListingRadios {
    display: flex;
    margin: 0 0 35px 0;
}

.FlightListingRadios .form-group {
    margin: 0 25px 0 0;
}


.DepartingFlightArea {
    padding: 25px;
    box-shadow: 0 4px 16px 0 rgba(17, 34, 17, 0.05);
    background-color: #fff;
    border-radius: 12px;
    margin: 0 0 25px 0;
}

.DepartingFlightArea .DepartingFlightHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.DepartingFlightArea .DepartingFlightHeading h6 {
    font-family: 'Switzer-bold';
    font-size: 16px;
    color: rgba(17, 34, 17, 1);
}

.DepartingFlightArea .DepartingFlightHeading a {
    font-family: 'Switzer-Semibold';
    font-size: 16px;
    color: rgba(3, 42, 82, 1);
    text-decoration: underline;
}

.DepartingFlightArea .FlightBrandName {
    position: relative;
    padding: 25px 0 25px 135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.DepartingFlightArea .FlightBrandName figure {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.DepartingFlightArea .FlightBrandName p {
    color: rgba(17, 34, 17, 0.75);
    margin: 0 0 5px 0;
    font-family: 'Switzer-Medium';
    font-size: 16px;
}

.DepartingFlightArea .FlightBrandName h4 {
    color: rgba(17, 34, 17, 1);
    font-family: 'Switzer-Semibold';
    font-size: 20px;
}

.DepartingFlightArea table {
    margin: 10px 0 20px 0;
}

.DepartingFlightArea table thead tr th {
    font-size: 16px;
    font-family: 'Switzer-bold';
    font-size: 16px;
    color: rgba(17, 34, 17, 1);
    padding: 7px 0;
}

.DepartingFlightArea table tbody tr td {
    color: rgba(17, 34, 17, 1);
    font-size: 16px;
    padding: 7px 0;
}

.DepartingFlightArea table tbody tr td:first-child {
    font-family: 'Switzer-Medium';
}

.DepartingFlightArea table tbody tr td:last-child {
    font-family: 'Switzer-Semibold';
    text-align: right;
}

.DepartingFlightArea .CheckBox {
    font-size: 17px;
}

.DepartingFlightArea .CheckBox strong {
    text-decoration: underline;
}

.DepartingFlightArea .BookingHotelChecks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px 0;
}

.DepartingFlightArea .BookingHotelChecks {
    border-bottom: 0.5px solid rgba(17, 34, 17, 0.25);
    border-top: 0.5px solid rgba(17, 34, 17, 0.25);
    padding: 5px 0;
}

.DepartingFlightArea .BookingHotelChecks h5 {
    color: rgba(17, 34, 17, 1);
    margin: 0 0 10px 0;
    font-size: 24px;
    font-family: 'Switzer-Semibold';
}

.DepartingFlightArea .BookingHotelChecks p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Medium';
}







.FlightTabBox {
    display: flex;
}

.FlightTabBox .AhbSideLeft {
    background-color: rgba(236, 30, 50, 1);
    border-radius: 8px 0 0 8px;
    padding: 50px 0 30px 20px;
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.FlightTabBox .AhbSideLeft .AhbSideSmall {
    position: relative;
}

.FlightTabBox .AhbSideLeft .AhbSideSmall.Center {
    padding: 0 0 0 50px;
}

.FlightTabBox .AhbSideLeft .AhbSideSmall.Center p {
    color: rgba(255, 255, 255, 0.78);
    font-size: 14px;
}

.FlightTabBox .AhbSideLeft .AhbSideSmall h1 {
    color: #fff;
    font-size: 32px;
    font-family: 'Switzer-semibold';
}

.FlightTabBox .AhbSideLeft .AhbSideSmall p {
    color: rgba(255, 255, 255, 0.60);
    font-size: 13px;
    font-family: 'Switzer-Medium';
}

.FlightTabBox .AhbSideLeft .AhbSideSmall figure {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.FlightTabBox .AhbSideRight {
    width: 80%;
}

.FlightTabBox {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    margin: 0 0 25px 0;
}

.FlightTabBox .AhbSideRight .AhbTop {
    display: flex;
    justify-content: space-between;
    padding: 25px 35px 25px 35px;
    border-bottom: 0.5px solid rgba(201, 202, 203, 1);
}

.FlightTabBox .AhbSideRight .AhbTop .AhbtSmall p {
    color: #000;
    font-size: 14px;
    margin: 0 0 7px 0;
    font-family: 'Switzer-Light';
}

.FlightTabBox .AhbSideRight .AhbTop .AhbtSmall h5 {
    color: #000;
    font-size: 16px;
}

.FlightTabBox .AhbSideRight .AhbTop .AhbtSmall h5 span {
    color: rgba(3, 42, 82, 1);
}

.FlightTabBox .AhbSideRight .AhbTop .AhbtSmall h5 span img {
    margin: 0 15px 0 10px;
}

.FlightTabBox .AhbSideRight .AhbTop .AhbtSmall .Confirmed {
    color: rgba(0, 204, 97, 1);
    display: flex;
    align-items: center;
}

.FlightTabBox .AhbSideRight .AhbCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 35px 30px 35px;
}

.FlightTabBox .AhbSideRight .AhbCenter .AhbCenterStart {
    position: relative;
    padding: 25px 0 25px 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.FlightTabBox .AhbSideRight .AhbCenter .AhbCenterStart figure {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 78px;
}

.FlightTabBox .AhbSideRight .AhbCenter .AhbCenterStart p {
    color: rgba(17, 34, 17, 0.75);
    margin: 0 0 5px 0;
    font-family: 'Switzer-Medium';
    font-size: 16px;
}

.FlightTabBox .AhbSideRight .AhbCenter .AhbCenterStart h4 {
    color: rgba(17, 34, 17, 1);
    font-family: 'Switzer-Semibold';
    font-size: 20px;
}

.FlightTabBox .AhbSideRight .AhbCenter {
    border-bottom: 0.5px solid rgba(201, 202, 203, 1);
}

.FlightTabBox .AhbSideRight .AhbCenter .AhbCenterCenter h6 {
    color: rgba(95, 167, 53, 1);
    font-family: 'Switzer-semibold';
    font-size: 16px;
}

.FlightTabBox .AhbSideRight .AhbCenter .AhbCenterEnd {
    display: flex;
    flex-direction: column;
}

.FlightTabBox .AhbSideRight .BnbdBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 35px 25px 35px;
}

.FlightTabBox .AhbSideRight .BnbdBottom .BnbdBottomLeft ul {
    display: flex;
    align-items: center;
}

.FlightTabBox .AhbSideRight .BnbdBottom .BnbdBottomLeft ul li {
    margin: 0 25px 0 0;
}

.FlightTabBox .AhbSideRight .BnbdBottom .BnbdBottomLeft ul li span {
    margin: 0 10px 0 0;
}

.FlightTabBox .AhbSideRight .BnbdBottom h6 span {
    font-size: 24px;
    font-family: 'Switzer-bold';
}

.FlightTabBox .AhbSideRight .BnbdBottom h6 {
    font-size: 12px;
}

.FlightTabBox .AhbSideRight .BnbdBottom .Price p {
    text-align: right;
    font-size: 12px;
    color: #000;
    font-family: 'Switzer-Light';
}

.BookingFlightNameArea {
    display: flex;
    justify-content: space-between;
    margin: 0 0 40px 0;
    align-items: center;
}

.BookingFlightNameArea .BookingFlightLeft h2 {
    color: rgba(17, 34, 17, 1);
    font-size: 24px;
    font-family: 'Switzer-bold';
    margin: 0 0 15px 0;
}

.BookingFlightNameArea .BookingFlightLeft p {
    color: rgba(17, 34, 17, 0.50);
    font-size: 14px;
    font-family: 'Switzer-Medium';
}

.BookingFlightNameArea .BookingFlightLeft p span {
    margin: 0 5px 0 0;
}

.BookingFlightNameArea .BookingFlightRight h1 {
    color: rgba(17, 34, 17, 1);
    font-size: 32px;
    font-family: 'Switzer-bold';
    margin: 0 0 15px 0;
    text-align: right;
}

.BookingFlightNameArea .BookingFlightRight .ShareDownloadIcons {
    display: flex;
    align-items: center;
}

.BookingFlightNameArea .BookingFlightRight .ShareDownloadIcons a.Icon {
    border-radius: 4px;
    border: 1px solid rgba(128, 128, 128, 1);
    width: 49px;
    height: 49px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0 10px 0 0;
}

.BookingFlightNameArea .BookingFlightRight .ShareDownloadIcons .DownloadBtn {
    background-color: rgba(128, 128, 128, 1);
    border-radius: 4px;
    color: rgba(17, 34, 17, 1);
    font-size: 14px;
    font-family: 'Switzer-Semibold';
    padding: 15px 30px;
    display: inline-block;
}

.TermsConditionsArea {
    padding: 50px 0 0 0;
}

.TermsConditionsArea .Heading h5 {
    color: #000;
    font-family: 'Switzer-semibold';
    color: #000;
    font-size: 24px;
    margin: 0 0 30px 0;
}

.TermsConditionsArea .TermsConditionsBox {
    margin: 0 0 50px 0;
}

.TermsConditionsArea .TermsConditionsBox h5 {
    color: #000;
    font-family: 'Switzer-semibold';
    color: #000;
    font-size: 24px;
    margin: 0 0 15px 0;
}

.TermsConditionsArea .TermsConditionsBox ul li {
    font-size: 18px;
    font-family: 'Switzer-Medium';
    color: rgba(102, 112, 133, 1);
    margin: 0 0 10px 0;
}

.TermsConditionsArea .TermsConditionsBox p {
    font-size: 18px;
    font-family: 'Switzer-Medium';
    color: rgba(102, 112, 133, 1);
    margin: 0 0 30px 0;
}


/* Flight Listing page css End  */

.CountryPackage {
    padding: 60px 100px;
}

.CountryPackage h1 {
    color: rgba(12, 20, 29, 1);
    letter-spacing: 0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 40px;
    margin: 0 0 30px 0;
}

.CountryPackage h1 span {
    font-size: 20px;
    color: rgba(12, 20, 29, 0.5);
    font-family: 'Switzer-Medium';
    margin: 0 0 0 10px;
}

.CountryPackage .CountryPackageMain {
    display: flex;
    margin: 100px 0 0 0;
}

.CountryPackage .CountryPackageLeft {
    width: 60%;
    padding: 0 40px 0 0;
}

.CountryPackage .CountryPackageLeft h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px;
}

.CountryPackage .CountryPackageLeft p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Medium';
    line-height: 36px;
    margin: 0 0 20px 0;
}

.CountryPackage .CountryPackageLeft a {
    border: 1px solid rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: rgba(243, 4, 44, 1);
    font-family: 'Switzer-Medium';
    padding: 6px 25px;
    display: inline-block;
}

.CountryPackage .CountryPackageRight {
    width: 40%;
}

.CountryPackage .CountryPackageRight ul {
    display: flex;
    flex-wrap: wrap;
}

.CountryPackage .CountryPackageRight ul li {
    width: calc(100% / 2 - 20px);
    margin: 0 10px 20px 10px;
}

.CountryPackage .CountryPackageRight ul li span img {
    width: 100%;
}

.ZoneAreaFilter {
    background-color: rgba(255, 255, 255, 1);
    border: 0.5px solid rgba(0, 0, 0, 0.3);
    border-left: none;
    border-right: none;
    padding: 20px 100px;
}

.ZoneAreaFilter .form-group {
    width: 100%;
    margin: 0;
}

.ZoneAreaFilter .form-group h6 {
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 15px 0;
}

.ZoneAreaFilter .form-group select {
    border: 1px solid rgba(121, 116, 126, 1);
    border-radius: 4px;
    color: rgba(51, 51, 51, 1);
    width: 100%;
    height: 45px;
    font-size: 14px;
}

.ZoneAreaFilter .form-group img {
    width: 100%;
}

.BudgetBox {
    background-color: #fff;
    border-radius: 10px;
    margin: 0 0 50px 0;
    padding: 10px;
    box-shadow: 0px 0px 10px #c9c2c2;
}

.BudgetBox .BudgetBoxImg {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 300px;
}

.BudgetBox .BudgetBoxImg img {
    width: 100%;
}

.BudgetBox .BudgetBoxImg span {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 5px 20px;
    font-size: 16px;
    font-family: 'Switzer-Medium';
}

.BudgetBox .BudgetBoxImg span.Blue {
    background-color: rgba(3, 42, 82, 1);
}

.BudgetBox .BudgetBoxImg span.Red {
    background-color: rgba(236, 30, 50, 1);
}

.BudgetBox .BudgetBoxImg span.Yellow {
    background-color: rgba(236, 30, 50, 1);
}

.BudgetBox .BudgetBoxImg span.GroupTour {
    background-color: rgba(3, 42, 82, 1);
    top: 0;
    bottom: auto;
}

.BudgetBox .BudgetBoxImg span.FlexiTour {
    background-color: rgba(3, 42, 82, 1);
    top: 0;
    bottom: auto;
}

.BudgetBox .BudgetBoxContent {
    padding: 10px 0 0;
}

.BudgetBox .BudgetBoxContent .BudgetBoxContentTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 10px 0;
}

.BudgetBox .BudgetBoxContent .BudgetBoxContentTop .date {
    color: rgba(152, 162, 179, 1);
    font-family: 'Switzer-Medium';
    font-size: 18px;
}

.BudgetBox .BudgetBoxContent .BudgetBoxContentTop h4 {
    color: rgba(236, 30, 50, 1);
    font-family: 'Switzer-Semibold';
    font-size: 17px;
    letter-spacing: 0.5px;
}



.BudgetBox .BudgetBoxContent h4 {
    font-size: 20px;
    font-family: 'Switzer-Semibold';
    color: rgba(12, 20, 29, 1);
    margin: 0 0 10px 0;
}

.BudgetBox .BudgetBoxContent p {
    font-size: 16px;
    font-family: 'Switzer-Medium';
    color: rgba(12, 20, 29, 0.5);
    margin: 0 0 10px 0;
}

.BudgetBox .BudgetBoxContent ul {
    display: flex;
    margin: 0 0 20px 0;
}

.BudgetBox .BudgetBoxContent ul li {
    color: rgba(152, 162, 179, 1);
    font-size: 16px;
    font-family: 'Switzer-Medium';
}

.BudgetBox .BudgetBoxContent ul li:first-child {
    margin: 0 50px 0 0;
}

.BudgetBox .BudgetBoxContent ul li span {
    margin: 0 10px 0 0;
    display: inline-block;
    width: 18px;
}

.BudgetBox .BudgetBoxContent .Price {
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-bold';
    font-size: 24px;
}

.BudgetBox .BudgetBoxContent .Price span {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-family: 'Switzer-Regular';
}

.ThemePackages {
    background-color: rgba(243, 243, 243, 1);
    padding: 60px 100px 50px;
}

.ThemePackages h2 {
    color: rgba(12, 20, 29, 1);
    font-family: 'Switzer-Semibold';
    font-size: 32px;
    margin: 0 0 50px 0;
}

.ThemePackages .ThemePackagesBox {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 1);
    padding: 8px 6px 0px;
}

.ThemePackages .ThemePackagesBox figure {
    border-radius: 3px;
    margin: 0;
    position: relative;
}

.ThemePackages .ThemePackagesBox figure .Overlay {
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Switzer-Regular';
    font-size: 14px;
}

.ThemePackages .ThemePackagesBox figure img {
    width: 100%;
}

.ThemePackages .ThemePackagesBox .ThemePackagesContent {
    padding: 25px 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ThemePackages .ThemePackagesBox .ThemePackagesContent h3 {
    color: rgba(12, 20, 29, 1);
    font-family: 'Switzer-Semibold';
    font-size: 18px;
}

.ThemePackages .ThemePackagesBox .ThemePackagesContent span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: rgba(242, 244, 247, 1);
    border-radius: 50%;
}

.ThemePackages .ThemePackagesBox .ThemePackagesContent span i {
    color: rgba(99, 90, 255, 1);
}


.PlacesToVisit {
    background-color: #fafbfc;
    padding: 60px 100px 0px;
}

.PlacesToVisit.Activities {
    padding: 60px 100px 50px;
}

.PlacesToVisit h2 {
    color: rgba(12, 20, 29, 1);
    font-family: 'Switzer-Semibold';
    font-size: 32px;
    margin: 0 0 50px 0;
}

.PlacesToVisit ul {
    display: flex;
    flex-wrap: wrap;
}

.PlacesToVisit ul li {
    width: calc(100% / 5 - 20px);
    margin: 0 10px;
}

.PlacesToVisit ul li figure {
    border-radius: 4px;
    position: relative;
}

.PlacesToVisit ul li figure img {
    width: 100%;
}

.PlacesToVisit ul li figcaption {
    position: absolute;
    left: 15px;
    bottom: 20px;
    right: 20px;
    color: rgba(255, 255, 255, 1);
    font-family: 'Switzer-Medium';
    font-size: 14px;
}

.RelatedPackages {
    background-color: rgba(243, 243, 243, 1);
    padding: 60px 100px 60px;
}

.RelatedPackages>h2 {
    color: rgba(12, 20, 29, 1);
    font-family: 'Switzer-Semibold';
    font-size: 32px;
    margin: 0 0 50px 0;
}

.FaqQuestions {
    background-color: #fafbfc;
    padding: 60px 100px 60px;
}

.FaqQuestions .section-title {
    margin: 0 0 50px 0;
}

.FaqQuestions .section-title h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
}

.FaqQuestions .StaticArea .panel-group {
    margin: 0;
}

.FaqQuestions .StaticArea .panel-group .panel {
    border-radius: 0;
}

.FaqQuestions .StaticArea .panel-group .panel+.panel {
    margin: 20px 0 0 0;
}

.FaqQuestions .StaticArea .panel-group .panel .panel-heading {
    cursor: pointer;
    border-radius: 0;
    padding: 15px 55px 15px 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FaqQuestions .StaticArea .panel-group .panel .panel-heading:after {
    content: '\f107';
    font-family: FontAwesome;
    color: #000;
    position: absolute;
    top: 18px;
    right: 20px;
    font-size: 18px;
    font-weight: 600;
    line-height: 14px;
    transition: 0.5s all ease-in-out
}

.FaqQuestions .StaticArea .panel-group .panel .panel-heading[aria-expanded="true"]:after {
    transform: rotate(180deg)
}

.FaqQuestions .StaticArea .panel-group .panel .panel-heading h4 {
    font-size: 20px;
    color: #000;
    font-family: 'Switzer-Semibold';
}

.FaqQuestions .StaticArea .panel-group .panel .panel-heading h4 span {
    margin: 0 10px 0px 0;
}

.FaqQuestions .StaticArea .panel-group .panel .panel-heading a {
    color: #000;
    font-size: 14px
}

.FaqQuestions .StaticArea .panel-group .panel .panel-body {
    padding: 15px 60px 15px 60px;
}

.FaqQuestions .StaticArea .panel-group .panel .panel-body:before {
    content: none
}

.FaqQuestions .StaticArea .panel-group .panel .panel-body p {
    font-size: 16px;
    line-height: 33px;
    font-family: 'Switzer-Medium';
    color: rgba(102, 112, 133, 1);
}

.FaqQuestions .StaticArea .panel-group .panel .panel-body p+p {
    margin-top: 15px
}


.PackageInformation {
    margin: 50px 0 0 0;
}

.PackageInformation>ul {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid rgba(152, 162, 179, 1);
    z-index: 1;
    position: sticky;
    top: 98px;
    margin-bottom: 0px;
}

.PackageInformation ul li a {
    color: rgba(102, 112, 133, 1);
    font-size: 15px;
    display: inline-block;
    padding: 15px 20px;
    font-family: 'Switzer-Medium';
}

.PackageInformation ul li a:focus {
    color: rgba(236, 30, 50, 1) !important;
}

.PackageInformation .OverviewDetails {
    padding: 15px 0;
    border-bottom: 0.5px solid rgba(200, 200, 200, 1);
    margin: 0 0 25px 0;
}

.PackageInformation .OverviewDetails h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 10px 0;
}

.PackageInformation .OverviewDetails .OdListing {
    margin: 0 0 25px 0;
}

.PackageInformation .OverviewDetails .OdListing h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 30px 0;
}

.PackageInformation .OverviewDetails .OdListing h6 {
    color: rgba(12, 20, 29, 1);
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .OverviewDetails .OdListing ul {
    margin: 0 0 0 30px;
}

.PackageInformation .OverviewDetails .OdListing ul li {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    list-style-type: disc;
}

.PackageInformation .OverviewDetails .OdListing ol {
    margin: 0px 0 0 30px;
}

.PackageInformation .OverviewDetails .OdListing ol li {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    list-style-type: disc;
    margin: 0 0 25px 0;
}

.Idactive {
    color: rgba(236, 30, 50, 1) !important;
    border-bottom: 1px solid rgba(236, 30, 50, 1);
}

.PackageInformation .ItineraryDetails {
    padding: 25px 0;
    border-top: 0.5px solid rgba(200, 200, 200, 1);
}

.PackageInformation .ItineraryDetails h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .ItineraryDetails .IdBox {
    margin-bottom: 20px;
}

.PackageInformation .ItineraryDetails .IdBox:nth-child(3) {
    border-bottom: 0.5px solid rgba(200, 200, 200, 1);
    padding: 0 0 25px 0;
}

.PackageInformation .ItineraryDetails .IdBox h3 {
    font-size: 24px;
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-bold';
    margin: 0 0 10px 0;
}

.PackageInformation .ItineraryDetails .IdBox h3 span {
    font-size: 24px;
    color: rgba(236, 30, 50, 1);
    margin: 0 0 0 5px;
}


.PackageInformation .ItineraryDetails .IdBox p {
    font-size: 15px;
    font-family: 'Switzer-Medium';
    color: #717171;
    margin: 0 0 10px 0;
    line-height: 30px;
}

.EfiBtn {
    color: rgba(243, 4, 44, 1);
    border: 1px solid rgba(243, 4, 44, 1);
    border-radius: 4px;
    padding: 10px;
    display: inline-block;
    font-family: 'Switzer-Medium';
}

.EfiBtn:hover {
    color: rgba(243, 4, 44, 1);
}

.PackageInformation .ItineraryDetails {
    border-top: 0.5px solid rgba(200, 200, 200, 1);
}

.PackageInformation .ItineraryDetails h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .PlacesVisitDetails {
    padding: 20px 0 0 0;
}

.PackageInformation .PlacesVisitDetails h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 23px;
    font-family: 'Switzer-bold';
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
}


.PackageInformation .PlacesVisitDetails p {
    color: #919090;
    font-size: 15px;
    margin: 0 0 25px 0;
}




.PackageInformation .SightseeingDetails {
    padding: 15px 0;
    border-top: 0.5px solid rgba(200, 200, 200, 1);
}

.PackageInformation .SightseeingDetails h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .SightseeingDetails ul {
    display: inline-flex;
    background-color: #fff;
}

.PackageInformation .SightseeingDetails ul li {
    border-bottom: 1px solid rgba(152, 162, 179, 1);
    padding: 10px;
}

.PackageInformation .SightseeingDetails ul li .nav-link {
    color: rgba(102, 112, 133, 1) !important;
    font-family: 'Switzer-Medium';
    font-size: 16px;
    border: none;
    padding: 10px 20px;
    text-align: center;
}

.PackageInformation .SightseeingDetails ul li .nav-link.active {
    border-bottom: 2px solid rgba(236, 30, 50, 1);
}

.PackageInformation .SightseeingDetails .tab-pane {
    padding: 25px 0 0 0;
}

.PackageInformation .SightseeingDetails .tab-pane .SdMain {
    display: flex;
    align-items: center;
    margin: 0 0 25px 0;
}

.PackageInformation .SightseeingDetails .tab-pane .SdMain .SdLeft,
.PackageInformation .SightseeingDetails .tab-pane .SdMain .SdRight {
    height: 100%;
}

.PackageInformation .SightseeingDetails .tab-pane .SdMain .SdLeft figure {
    border-radius: 4px;
    margin: 0px;
    width: 250px;
    height: 165px;
}

.PackageInformation .SightseeingDetails .tab-pane .SdMain .SdRight {
    padding: 0px 0 0px 30px;
}

.PackageInformation .SightseeingDetails .tab-pane .SdMain .SdRight h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 20px;
    font-family: 'Switzer-bold';
    margin: 0 0 7px 0;
}

.PackageInformation .SightseeingDetails .tab-pane .SdMain .SdRight p {
    color: rgba(12, 20, 29, 0.5);
    font-family: 'Switzer-Medium';
    font-size: 14px;
    margin: 0 0 12px 0;
}

.PackageInformation .SightseeingDetails .tab-pane .SdMain .SdRight .EfiBtn {
    padding: 5px 20px;
}


.PackageInformation .HotelsDetails {
    padding: 15px 0;
    border-top: 0.5px solid rgba(200, 200, 200, 1);
}

.PackageInformation .HotelsDetails h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .HotelsDetails ul {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid rgba(152, 162, 179, 1);
    width: 90%;
}

.PackageInformation .HotelsDetails ul li .nav-link {
    color: rgba(102, 112, 133, 1) !important;
    font-family: 'Switzer-Medium';
    font-size: 16px;
    border: none;
    padding: 15px 10px;
    text-align: center;
}

.PackageInformation .HotelsDetails ul li .nav-link.active {
    border-bottom: 2px solid rgba(236, 30, 50, 1);
}

.PackageInformation .HotelsDetails .tab-pane {
    padding: 40px 0 0 0;
}

.PackageInformation .HotelsBox figure {
    border-radius: 4px;
    position: relative;
    margin: 0 0 10px 0;
}

.PackageInformation .HotelsBox figure img {
    width: 100%;
}

.PackageInformation .HotelsBox figure .HotelsBoxOverlay {
    position: absolute;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 5px;
    right: 10px;
}

.PackageInformation .HotelsBox figure .HotelsBoxOverlay h6 {
    color: #fff;
    font-size: 14px;
    margin: 0 5px 0 0;
}

.PackageInformation .HotelsBox figure .HotelsBoxOverlay i {
    color: rgba(252, 211, 77, 1);
    font-size: 10px;
    margin: 0 3px 0 0;
}

.PackageInformation .HotelsBox figure .HotelsBoxOverlay p {
    font-size: 12px;
    color: #fff;
    font-family: 'Switzer-Regular';
}

.PackageInformation .HotelsBox h5 {
    color: rgba(12, 20, 29, 1);
    font-family: 'Switzer-Semibold';
    font-size: 16px;
    margin: 0 0 5px 0;
}

.PackageInformation .HotelsBox h5 {
    color: rgba(12, 20, 29, 1);
    font-size: 14px;
}


.PackageInformation .CruiseDetails {
    padding: 10px 0;
}

.PackageInformation .CruiseDetails h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .CruiseDetails figure {
    border-radius: 12px;
    margin: 0 0 40px 0;
}

.PackageInformation .CruiseDetails figure img {
    width: 100%;
}

.PackageInformation .CruiseDetails table {
    margin: 0 0 20px 0;
}

.PackageInformation .CruiseDetails table thead tr th:first-child {
    text-align: left;
}

.PackageInformation .CruiseDetails table thead tr {
    border-bottom: 2px solid rgba(152, 162, 179, 0.75);
    border-top: 0.5px solid rgba(152, 162, 179, 0.75);
}

.PackageInformation .CruiseDetails table thead tr th {
    text-align: center;
    padding: 10px 0 10px 0;
}

.PackageInformation .CruiseDetails table tbody tr td:first-child {
    text-align: left;
}

.PackageInformation .CruiseDetails table tbody tr {
    border-bottom: 0.5px solid rgba(152, 162, 179, 0.75);
}

.PackageInformation .CruiseDetails table tbody tr:last-child {
    border-bottom: none
}

.PackageInformation .CruiseDetails table tbody tr td {
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
    padding: 10px 0 10px 0;
}

.PackageInformation .CruiseDetails p {
    color: rgba(0, 0, 0, 0.75);
}

.PackageInformation .CruiseDetails h6 {
    color: rgba(12, 20, 29, 1);
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 40px 0;
}

.PackageInformation .OptionalDetails {
    padding: 175px 0 25px 0;
}

.PackageInformation .OptionalDetails h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .OptionalDetails ul {
    display: inline-flex;
    background-color: #fff;
    border-bottom: 1px solid rgba(152, 162, 179, 1);
}

.PackageInformation .OptionalDetails ul li .nav-link {
    color: rgba(102, 112, 133, 1) !important;
    font-family: 'Switzer-Medium';
    font-size: 16px;
    border: none;
    padding: 15px 25px;
    text-align: center;
}

.PackageInformation .OptionalDetails ul li .nav-link.active {
    border-bottom: 2px solid rgba(236, 30, 50, 1);
}

.PackageInformation .OptionalDetails .tab-pane {
    padding: 30px 0 0 0;
}

.PackageInformation .OptionalDetails .tab-pane .form-control {
    border-radius: 4px;
    color: rgba(51, 51, 51, 1);
    font-size: 16px;
    margin: 0 0 35px 0;
    padding: 30px 10px;
    border: 1px solid rgba(121, 116, 126, 1);
}

.PackageInformation .OptionalDetails .tab-pane .form-control::placeholder {
    color: rgba(51, 51, 51, 1);
}

.PackageInformation .OptionalDetails .OptionalsBox {
    background-color: #fff;
    margin: 0 0 30px 0;
}

.PackageInformation .OptionalDetails .OptionalsBox figure {
    border-radius: 4px;
    margin: 0;
    width: 247px;
}

.PackageInformation .OptionalDetails .OptionalsBox {
    display: flex;
    align-items: center;
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight {
    margin: 0 0 0 20px;
    width: calc(100% - 247px - 20px);
    padding: 0 30px 0 0;
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxTop {
    display: flex;
    justify-content: space-between;
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxTop .OptionalsBoxTopLeft H4 {
    font-size: 22px;
    font-family: 'Switzer-Semibold';
    color: rgba(12, 20, 29, 1);
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxTop .OptionalsBoxTopLeft p {
    font-size: 14px;
    color: rgba(12, 20, 29, 0.5);
    margin: 15px 0 0 0;
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxTop .OptionalsBoxTopLeft p span {
    color: rgba(12, 20, 29, 1);
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxTop .OptionalsBoxTopRight h5 {
    color: rgba(0, 0, 0, 1);
    margin: 0 0 15px 0;
    font-size: 20px;
    font-family: 'Switzer-bold';
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxTop .OptionalsBoxTopRight span {
    font-size: 12px;
    font-family: 'Switzer-Regular';
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 0 0;
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxBottom .EfiBtn {
    padding: 5px 10px !important;
}

.PackageInformation .OptionalDetails .OptionalsBox .OptionalsBoxRight .OptionalsBoxBottom .BrandBtn {
    padding: 5px 50px !important;
    width: auto;
}


.PackageInformation .TourInformation {
    padding: 175px 0 0 0;
    margin: 0 0 25px 0;
}

.PackageInformation .TourInformation h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .TourInformation .OdListing {
    margin: 0 0 25px 0;
}

.PackageInformation .TourInformation .OdListing h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 30px 0;
}

.PackageInformation .TourInformation .OdListing h6 {
    color: rgba(12, 20, 29, 1);
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .TourInformation .OdListing ul {
    margin: 0 0 0 30px;
}

.PackageInformation .TourInformation .OdListing ul li {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    list-style-type: disc;
}

.PackageInformation .TourInformation .OdListing ol {
    margin: 0px 0 0 30px;
}

.PackageInformation .TourInformation .OdListing ol li {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    list-style-type: disc;
    margin: 0 0 25px 0;
}



.PackageInformation .TcsTermsAndConditions {
    padding: 75px 0 0 0;
    margin: 0 0 25px 0;
}

.PackageInformation .TcsTermsAndConditions h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.PackageInformation .TcsTermsAndConditions a {
    color: rgba(26, 99, 243, 1);
    font-size: 18px;
    margin: 0 0 25px 0;
    display: inline-block;
    text-decoration: underline;
    font-style: italic;
}

.PackageInformation .TcsTermsAndConditions p {
    font-family: 'Switzer-Medium';
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    margin: 0 0 20px 0;
}


.CalculatePrice {
    background-color: #fff;
    border: 1px solid rgba(234, 236, 240, 1);
    padding: 20px 10px;
    margin: 50px 0 0 0;
}

.CalculatePrice .Heading {
    border-bottom: 1px solid rgba(208, 213, 221, 1);
    margin: 0 0 20px 0;
    padding: 0 0 20px 0;
}

.CalculatePrice h4 {
    font-size: 20px;
    color: rgba(12, 20, 29, 1);
    font-family: 'Switzer-Semibold';
}

.CalculatePrice .form-group {
    margin: 0 0 20px 0;
}

.CalculatePrice .form-group h6 {
    margin: 0 0 10px 0;
    font-family: 'Switzer-Medium';
}

.CalculatePrice .form-group h6 span {
    color: rgba(240, 68, 56, 1);
}

.CalculatePrice .form-group .form-control {
    border-radius: 4px;
    border: 1px solid rgba(208, 213, 221, 1);
    height: auto;
    padding: 15px 15px;
}

.CalculatePrice .form-group .form-control:focus {
    box-shadow: none;
}

.CalculatePrice .form-group .PlusMinusBox {
    border: 1px solid rgba(208, 213, 221, 1);
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.CalculatePrice .form-group .PlusMinusBox h6 {
    margin: 0 10px;
}

.CalculatePrice .form-group .PlusMinusBox .Icon {
    border: 1px solid rgba(208, 213, 221, 1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 27px;
    height: 27px;
}

.CalculatePrice .TransferBox {
    border-top: 1px solid rgba(208, 213, 221, 1);
    border-bottom: 1px solid rgba(208, 213, 221, 1);
    padding: 20px 0 0 0;
}

.CalculatePrice .TransferBox h6 {
    font-size: 16px;
    margin: 0 0 15px 0;
    color: #000;
    font-family: 'Switzer-Medium';
}

.CalculatePrice .HotelTypeArea {
    padding: 20px 0 0 0;
}

.CalculatePrice .HotelTypeArea h6 {
    font-size: 16px;
    margin: 0 0 15px 0;
    color: #000;
    font-family: 'Switzer-Medium';
}

.CalculatePrice .HotelTypeArea .HotelTypeLr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.CalculatePrice .HotelTypeArea .HotelTypeLeft {
    color: rgba(102, 112, 133, 1);
    font-size: 12px;
    display: flex;
    flex-direction: column;
}

.CalculatePrice .HotelTypeArea .HotelTypeRight {
    text-align: right;
}

.CalculatePrice .HotelTypeArea .HotelTypeRight h2 {
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-bold';
    font-size: 24px;
    margin: 0 0 5px 0;
}

.CalculatePrice .HotelTypeArea .BookNow {
    background-color: rgba(236, 30, 50, 1);
    border-radius: 2px;
    display: inline-block;
    width: 100%;
    color: #fff;
    font-family: 'Switzer-Medium';
    text-align: center;
    font-size: 16px;
    padding: 13px 0;
    margin: 0 0 20px 0;
}

.CalculatePrice .HotelTypeArea .SendEnquiry {
    background-color: transparent;
    color: rgba(243, 4, 44, 1);
    border: 1px solid rgba(243, 4, 44, 1);
    border-radius: 2px;
    display: inline-block;
    width: 100%;
    font-family: 'Switzer-Medium';
    text-align: center;
    font-size: 16px;
    padding: 13px 0;
    margin: 0 0 10px 0;
}

.CalculatePrice .HotelTypeArea .ShareQuoteBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.CalculatePrice .HotelTypeArea .ShareQuoteBox .ShareQuoteBtn {
    border: 1px solid rgba(152, 162, 179, 1);
    border-radius: 4px;
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-Medium';
    display: inline-block;
    width: 100%;
    height: 52px;
    margin: 0 15px 0 0;
    padding: 13px 0;
    text-align: center;
}

.CalculatePrice .HotelTypeArea .ShareQuoteBox .DownloadIcon {
    border: 1px solid rgba(152, 162, 179, 1);
    border-radius: 4px;
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-Medium';
    display: inline-block;
    width: 72px;
    height: 52px;
    padding: 13px 0;
    text-align: center;
}



.PackageDetailsHero {
    padding: 0px 0 0 0;
    margin: 0 0 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.PackageDetailsHero h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 15px 0;
}

.PackageDetailsHero {
    display: flex;
}

.PackageDetailsHero .PdhLeft {
    width: 70%;
}

.PackageDetailsHero .PdhRight {
    width: 30%;
    text-align: right;
}

.PackageDetailsHero .PdhLeft p {
    color: rgba(12, 20, 29, 0.5);
    font-size: 16px;
    font-family: 'Switzer-Medium';
}

.PackageDetailsHero .PdhLeft .DaysBox {
    display: flex;
    justify-content: space-between;
    margin: 0 0 15px 0;
}

.PackageDetailsHero .PdhLeft .DaysBox h3 {
    color: rgba(236, 30, 50, 1);
    font-size: 24px;
    font-family: 'Switzer-Semibold';
}

.PackageDetailsHero .PdhLeft .DaysBox ul {
    display: flex;
}

.PackageDetailsHero .PdhLeft .DaysBox ul li {
    color: rgba(152, 162, 179, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
}

.PackageDetailsHero .PdhLeft .DaysBox ul li:first-child {
    margin: 0 10px 0 0;
}

.PackageDetailsHero .PdhLeft .DaysBox ul li span {
    margin: 0 10px 0 0;
}

.PackageDetailsHero .PdhRight a {
    border: 1px solid rgba(152, 162, 179, 1);
    border-radius: 4px;
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 10px 15px;
    align-items: center;
    height: 50px;
    font-family: 'Switzer-Medium';
    color: rgba(12, 20, 29, 1);
    font-size: 16px;
    white-space: nowrap;
}

.PackageDetailsHero .PdhRight a img {
    margin: 0 10px 0 0;
}

.PackageDetailsHero .PdhRight a:last-child img {
    margin: 0;
}

.PdhImages figure {
    margin: 0px;
}

.PdhImages figure img {
    width: 100%;
}


.CancellationPolicyArea {
    background-color: #fff;
    border-radius: 4px;
    padding: 25px;
    margin: 0 0 25px 0;
    border: 1px solid rgba(234, 236, 240, 1);
}

.CancellationPolicyArea .Heading {
    border-bottom: 1px solid rgba(208, 213, 221, 1);
    padding: 0 0 15px 0;
}

.CancellationPolicyArea .Heading h3 {
    color: rgba(12, 20, 29, 1);
    font-size: 20px;
    font-family: 'Switzer-Semibold';
}

.CancellationPolicyArea .CancelationCharges {
    padding: 15px 0;
    border-bottom: 1px solid rgba(208, 213, 221, 1);
}

.CancellationPolicyArea .CancelationCharges h5 {
    margin: 0 0 10px 0;
    font-family: 'Switzer-Medium';
}

.CancellationPolicyArea .CancelationCharges p {
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
    margin: 0 0 5px 0;
}

.CancellationPolicyArea .CancelationCharges p:last-child {
    margin: 0px;
}

.CancellationPolicyArea .SpecialRequest {
    padding: 15px 0;
}

.CancellationPolicyArea .SpecialRequest h5 {
    margin: 0 0 15px 0;
    font-family: 'Switzer-Medium';
}

.CancellationPolicyArea .SpecialRequest .form-control {
    margin: 0 0 10px 0;
    border: 1px solid rgba(208, 213, 221, 1);
    padding: 15px;
    height: auto;
}

.CancellationPolicyArea .SpecialRequest .CheckBox {
    font-size: 16px;
}

.CancellationPolicyArea .FullPartPayment {
    border-top: 1px solid rgba(208, 213, 221, 1);
    padding: 25px 0 0 0;
}

.CancellationPolicyArea .FullPartPayment .form-group {
    margin: 0 0 25px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.CancellationPolicyArea .FullPartPayment .form-group p {
    margin: 0;
}

.CancellationPolicyArea .FullPartPayment .form-group .Radio {
    color: #000;
    padding-left: 35px;
    width: 100%;
    margin-bottom: 10px !important;
    font-size: 18px;
}

.CancellationPolicyArea .FullPartPayment .form-group .Radio.active {
    color: #EC1E32;
}

.CancellationPolicyArea .FullPartPayment .form-group h3 {
    font-family: 'Switzer-bold';
    font-size: 16px;
}

.BookHotelDetailsArea {
    background-color: rgba(255, 255, 255, 1);
    /* box-shadow: 0 4px 16px 0 rgba(255, 255, 255, 1); */
    box-shadow: 0px 4px 16px 0 rgba(17, 34, 17, 0.05);
    padding: 25px;
    margin-bottom: 25px;
    border-radius: 12px;
}

.BookHotelDetailsArea .OverviewDetails {
    padding: 75px 0 0 0;
}

.BookHotelDetailsArea .OverviewDetails ul {
    display: inline-flex;
    border-bottom: 1px solid rgba(152, 162, 179, 1);
}

.BookHotelDetailsArea .OverviewDetails ul li .nav-link {
    color: rgba(102, 112, 133, 1) !important;
    font-family: 'Switzer-Medium';
    font-size: 16px;
    border: none;
    padding: 15px;
    text-align: center;
}

.BookHotelDetailsArea .OverviewDetails ul li .nav-link.active {
    border-bottom: 2px solid rgba(236, 30, 50, 1);
}


.BookHotelDetailsArea .OverviewDetails .OdListing {
    margin: 0 0 25px 0;
}

.BookHotelDetailsArea .OverviewDetails .OdListing h2 {
    color: rgba(12, 20, 29, 1);
    font-size: 28px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 30px 0;
}

.BookHotelDetailsArea .OverviewDetails .OdListing h6 {
    color: rgba(12, 20, 29, 1);
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.BookHotelDetailsArea .OverviewDetails .OdListing ul {
    margin: 0 0 25px 30px;
    display: block;
    border-bottom: none;
}

.BookHotelDetailsArea .OverviewDetails .OdListing ul li {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    list-style-type: disc;
}

.BookHotelDetailsArea .OverviewDetails .OdListing ol {
    margin: 0px 0 0 30px;
}

.BookHotelDetailsArea .OverviewDetails .OdListing ol li {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    list-style-type: disc;
    margin: 0 0 25px 0;
}

.BookHotelDetailsArea .OverviewDetails .tab-pane {
    padding: 25px 0 0 0;
}


.BookHotelDetailsArea .BookingHotelChecks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px 0;
}

.BookHotelDetailsArea .BookingHotelChecks h5 {
    color: rgba(17, 34, 17, 1);
    font-size: 20px;
    font-family: 'Switzer-Semibold';
}

.BookHotelDetailsArea .BookingHotelChecks p {
    color: rgba(102, 112, 133, 1);
    margin: 0 0 10px 0;
    font-size: 16px;
}



.BookHotelDetailsArea .HotelNameRatings {
    border: 0.5px solid rgba(141, 211, 187, 1);
    border-radius: 8px;
    position: relative;
    padding: 25px 0 25px 110px;
    margin: 0 0 50px 0;
}

.BookHotelDetailsArea .HotelNameRatings figure {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
}

.BookHotelDetailsArea .HotelNameRatings h3 {
    font-family: 'Switzer-Semibold';
    font-size: 22px;
    margin: 0 0 15px 0;
}

.BookHotelDetailsArea .HotelNameRatings h6 {
    color: rgba(236, 30, 50, 1);
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 10px 0;
}

.BookHotelDetailsArea .HotelNameRatings ul {
    display: flex;
}

.BookHotelDetailsArea .HotelNameRatings ul li {
    color: rgba(152, 162, 179, 1);
    font-size: 14px;
    font-family: 'Switzer-Medium';
}

.BookHotelDetailsArea .HotelNameRatings ul li:first-child {
    margin: 0 50px 0 0;
}

.BookHotelDetailsArea .HotelNameRatings ul li span {
    margin: 0 10px 0 0;
    width: 20px;
    display: inline-block;
}



.EnterCustomerDetails .HotelNameRatings {
    border: 0.5px solid rgba(141, 211, 187, 1);
    border-radius: 8px;
    position: relative;
    padding: 25px 0 25px 110px;
    margin: 0 0 50px 0;
}

.EnterCustomerDetails .HotelNameRatings.Transfer {
    padding: 25px 0 25px 135px;
}

.EnterCustomerDetails .HotelNameRatings figure {
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
}

.EnterCustomerDetails .HotelNameRatings h3 {
    font-family: 'Switzer-Semibold';
    font-size: 24px;
}

.EnterCustomerDetails .HotelNameRatings h6 {
    font-family: 'Switzer-Semibold';
    font-size: 18px;
}

.EnterCustomerDetails .HotelNameRatings p {
    color: rgba(102, 112, 133, 1);
    margin: 12px 0;
    font-size: 16px;
}

.EnterCustomerDetails .HotelNameRatings p img {
    margin: 0 5px 0 0;
}

.EnterCustomerDetails .BookingHotelChecks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px 0;
}

.EnterCustomerDetails .BookingHotelChecks h5 {
    color: rgba(17, 34, 17, 1);
    margin: 0 0 10px 0;
    font-size: 20px;
    font-family: 'Switzer-Semibold';
}

.EnterCustomerDetails .BookingHotelChecks p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
}

.EnterCustomerDetails .FlightNumberArea h5 {
    font-size: 20px;
    color: #000;
    font-family: 'Switzer-Medium';
    margin: 0 0 15px 0;
}

.EnterCustomerDetails .FlightNumberArea .YellowBox {
    background-color: rgba(255, 251, 239, 1);
    padding: 10px 20px;
    margin: 0 0 10px 0;
    font-size: 12px;
}

.EnterCustomerDetails .FlightNumberArea h6 {
    font-size: 16px;
    color: #000;
    font-family: 'Switzer-Medium';
    margin: 0 0 15px 0;
}

.EnterCustomerDetails .FlightNumberArea p {
    color: #000;
    font-size: 12px;
    font-family: 'Switzer-Medium';
    margin: 0 0 10px 0;
}

.EnterCustomerDetails .FlightNumberArea .DateTime {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
    margin: 15px 0;
}

.EnterCustomerDetails .FlightNumberArea .OutBoundBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.EnterCustomerDetails .FlightNumberArea .OutBoundBox .OutboundSmall {
    position: relative;
    padding: 0 0 0 30px;
}

.EnterCustomerDetails .FlightNumberArea .OutBoundBox .OutboundSmall figure {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.EnterCustomerDetails .FlightNumberArea .OutBoundBox .OutboundSmall h6 {
    font-family: 'Switzer-Medium';
    font-size: 12px;
    margin: 0 0 0px 0;
}

.EnterCustomerDetails .FlightNumberArea .OutBoundBox .OutboundSmall p {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Light';
    font-size: 12px;
}

.EnterCustomerDetails .FlightNumberArea .OutBoundBox .OutboundSmall.Green h6 {
    color: rgba(0, 204, 97, 1);
}

.EnterCustomerDetails .FlightNumberArea .OutBoundBox .OutboundSmall.Red h6 {
    color: rgba(255, 0, 0, 1);
}

.EnterCustomerDetails .FlightNumberArea .OutBoundBox h6 {
    color: rgba(17, 34, 17, 1);
    font-family: 'Switzer-Semibold';
    font-size: 16px;
}



.AhbBottom .AhbBottomStartRight .AhbBottomStartRightBottom.Transfer {
    display: flex;
}

.AhbBottom .AhbBottomStartRight .AhbBottomStartRightBottom .OutboundSmall {
    position: relative;
    padding: 0 0 0 30px;
}

.AhbBottom .AhbBottomStartRight .AhbBottomStartRightBottom .OutboundSmall figure {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0px;
}

.AhbBottom .AhbBottomStartRight .AhbBottomStartRightBottom .OutboundSmall h6 {
    font-family: 'Switzer-Medium';
    font-size: 12px;
    margin: 0 0 0px 0;
}

.AhbBottom .AhbBottomStartRight .AhbBottomStartRightBottom .OutboundSmall p {
    color: rgba(102, 112, 133, 1);
    font-family: 'Switzer-Light';
    font-size: 12px;
}

.AhbBottom .AhbBottomStartRight .AhbBottomStartRightBottom .OutboundSmall.Green h6 {
    color: rgba(0, 204, 97, 1);
}

.AhbBottom .AhbBottomStartRight .AhbBottomStartRightBottom .OutboundSmall.Red h6 {
    color: rgba(255, 0, 0, 1);
}

.AhbBottom .AhbBottomStartRight .AhbBottomStartRightBottom.Transfer .OpenATicket {
    border-radius: 4px;
    border: 0.5px solid rgba(201, 202, 203, 1);
    padding: 8px 30px;
    font-size: 13px;
    color: #000;
    font-family: 'Switzer-Light';
    margin: 0 0 0 25px;
}



.AboutSection {
    padding: 0 0 50px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.AboutArea h4 {
    color: rgba(0, 0, 0, 1);
    font-family: 'Switzer-Semibold';
    font-size: 20px;
    margin: 0 0 25px 0;
}

.AboutArea p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
    margin: 0 0 30px 0;
}


.CalendarArea h4 {
    color: #000000;
    font-size: 20px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.SelectCategoryArea h4 {
    color: #000000;
    font-size: 20px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 25px 0;
}

.SelectCategoryArea h6 {
    color: #000000;
    font-family: 'Switzer-Medium';
    font-size: 16px;
    margin: 0 0 20px 0;
}

.SelectCategoryArea h5 {
    color: #008000;
    font-family: 'Switzer-Light';
    font-size: 16px;
    margin: 0 0 35px 0;
}

.SelectCategoryArea h5 span {
    color: #008000;
    font-family: 'Switzer-Semibold';
    font-size: 16px;
}

.SelectCategoryArea .form-group {
    display: flex;
}

.SelectCategoryArea .form-group .Radio:last-child {
    margin: 0 0 0 50px !important;
}


.SelectHourTour h6 {
    color: #112211;
    font-family: 'Switzer-bold';
    font-size: 16px;
    margin: 0 0 25px 0;
}

.FourBoxes {
    display: flex;
}

.FourBoxes li {
    margin: 0 15px 0 0;
    border: 1px solid rgba(3, 42, 82, 1);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    padding: 15px 0 15px 20px;
    height: 145px;
    width: 160px;
}

.FourBoxes li.First {
    justify-content: flex-end;
}

.FourBoxes li p {
    margin: 0;
    font-size: 14px;
    font-family: 'Switzer-Medium';
    color: rgba(3, 42, 82, 1);
}

.FourBoxes li h1 {
    position: absolute;
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-bold';
    top: 15px;
    left: 20px;
}

.FourBoxes li h6 {
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
}

.FourBoxes li h5 {
    color: rgba(3, 42, 82, 1);
    font-family: 'Switzer-bold';
    font-size: 16px;
    margin: 0 0 5px 0;
}

.FourBoxes li:hover {
    background-color: rgba(3, 42, 82, 1);
}

.FourBoxes li:hover h6,
.FourBoxes li:hover h5,
.FourBoxes li:hover p,
.FourBoxes li:hover h1 {
    color: #fff;
}

.FourBoxes li:hover span {
    filter: brightness(0) invert(1);
}

.PriceDetailsTable {
    padding: 20px 0 0 0;
}

.PriceDetailsTable table tr th {
    color: #112211;
    font-family: 'Switzer-bold';
    font-size: 20px;
    padding: 0 0 10px 0;
}

.PriceDetailsTable table tr td {
    font-size: 16px;
    color: #112211;
    padding: 5px 0;
}

.PriceDetailsTable table tr td:first-child {
    font-family: 'Switzer-Medium';
}

.PriceDetailsTable table tr td:last-child {
    font-family: 'Switzer-bold';
    text-align: right;
}

.PointsInterest {
    padding: 50px 0 0 0;
}

.PointsInterest h4 {
    color: #112211;
    font-family: 'Switzer-bold';
    margin: 0 0 20px 0px;
    font-size: 20px;
}

.PointsInterest ul {
    margin: 0 0 0 15px;
}

.PointsInterest ul li {
    list-style-type: disc;
    color: #112211;
    font-family: 'Switzer-Semibold';
}

.StartingPoints {
    padding: 50px 0 25px 0;
    margin: 0 0 50px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.StartingPoints h4 {
    color: #112211;
    font-family: 'Switzer-bold';
    font-size: 22px;
    margin: 0 0 15px 0;
}

.StartingPoints h6 {
    color: rgba(17, 34, 17, 0.75);
    font-family: 'Switzer-bold';
    margin: 0 0 20px 0;
    font-size: 18px;
}

.StartingPoints ul {
    margin-left: 25px;
}

.StartingPoints ul li {
    font-size: 16px;
    list-style-type: disc;
    margin: 0 0 20px 0;
    color: rgba(17, 34, 17, 0.75);
}

.StartingPoints ul li:nth-child(2) {
    font-style: italic;
}

.HalfDayMorning {
    display: flex;
    align-items: center;
    margin: 20px 0 0px 0;
}

.HalfDayMorning h4 {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
}

.HalfDayMorning h5 {
    font-family: 'Switzer-Semibold';
    margin: 0 0 0 35px;
    font-size: 14px;
}

.HalfDayMorning h5 img {
    margin: 0 5px 0 0;
}

.HalfDayMorning h5 span {
    font-family: 'Switzer-Regular';
}



.BookNowBoxTop {
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(17, 34, 17, 0.05);
    margin: 0 0 25px 0;
    padding: 20px 25px;
}

.BookNowBoxTop.Tabs {
    padding: 0px;
}

.BookNowBoxTop .nav-pills.nav-fill .nav-item {
    text-align: left !important;
}

.BookNowBoxTop .nav-pills.nav-fill .nav-item:first-child {
    border-right: 1px solid rgba(215, 226, 238, 1);
}

.BookNowBoxTop .nav-pills.nav-fill .nav-item .nav-link {
    padding: 25px;
}

.BookNowBoxTop .nav-pills.nav-fill .nav-item .nav-link.active {
    border-bottom: 4px solid rgba(128, 128, 128, 1);
    background-color: transparent;
}

.BookNowBoxTop h6 {
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 10px 0;
    color: rgba(17, 34, 17, 1);
}

.BookNowBoxTop p {
    font-size: 14px;
    color: rgba(17, 34, 17, 0.40);
}


.ChechBoxShow {
    background-color: rgba(243, 4, 44, 1);
    padding: 25px;
    display: flex;
    justify-content: space-between;
}

.ChechBoxShow .CheckBoxStart .CheckBoxStartTop {
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px 0;
}

.ChechBoxShow .CheckBoxStart .CheckBoxStartTop h5 {
    color: #fff;
    font-size: 14px;
    font-family: 'Switzer-Semibold';
}

.ChechBoxShow .CheckBoxStart .CheckBoxStartTop h6 {
    color: #fff;
    font-size: 13px;
    font-family: 'Switzer-Semibold';
    text-decoration: underline;
}

.ChechBoxShow .CheckBoxStart .CheckBoxStartBottom {
    position: relative;
    padding: 0 0 0 52px;
    display: flex;
    justify-content: space-between;
}

.ChechBoxShow .CheckBoxStart .CheckBoxStartBottom figure.Second {
    margin: 0 10px;
}

.ChechBoxShow .CheckBoxStart .CheckBoxStartBottom figure:first-child {
    position: absolute;
    left: 0;
    width: 42px;
    margin: 0 0 10px 0;
}

.ChechBoxShow .CheckBoxStart .CheckBoxStartBottom h5 {
    color: #fff;
    font-size: 14px;
    font-family: 'Switzer-Semibold';
}

.ChechBoxShow .CheckBoxStart .CheckBoxStartBottom p {
    color: #fff;
    font-size: 13px;
    font-family: 'Switzer-Medium';
}

.ChechBoxShow .CheckBoxEnd {
    text-align: right;
}

.ChechBoxShow .CheckBoxEnd a {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 1);
    padding: 8px 16px;
    font-size: 14px;
    font-family: 'Switzer-Semibold';
    margin: 0 0 5px 0;
    display: inline-block;
    color: rgba(243, 4, 44, 1);
}

.ChechBoxShow .CheckBoxEnd h3 {
    color: rgba(255, 255, 255, 1);
    font-family: 'Switzer-bold';
    font-size: 18px;
}

.ChechBoxShow .CheckBoxEnd h3 span {
    font-size: 12px;
    font-family: 'Switzer-Regular';
}


.SpanBlue {
    border-radius: 5px;
    background-color: rgba(234, 240, 255, 1);
    padding: 5px 10px;
    margin: 0 10px 0 0;
}

.SpanBlue img {
    margin: 0 0 0 10px;
    width: 16px;
}


.MealsBoxMain {
    display: flex;
    margin: 25px 0 0 0;
}

.MealsBoxMain .MealsBox {
    margin: 0 10px;
    text-align: center;
}

.MealsBoxMain .MealsBox h4 {
    font-size: 15px;
    color: #000;
    font-family: 'Switzer-Regular';
    text-align: center;
}



.ShowAllPhotos {
    position: relative;
}

.ShowAllPhotos figcaption {
    position: absolute;
    left: 50%;
    top: 50%;
    color: rgba(255, 255, 255, 1);
    font-family: 'Switzer-Medium';
    font-size: 16px;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 10px 5px;
}

.UploadPassport {
    width: 148px;
    height: 36px;
    border: 1px dashed rgba(201, 202, 203, 1);
    position: relative;
    display: inline-flex;
    margin: 0 10px 0 0;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-family: 'Switzer-Regular';
    align-items: center;
    justify-content: center;
}

.UploadPassport input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
}



.TransferFilter .TopMainBox {
    background-color: #fff;
    box-shadow: 0 4px 16px 0 rgba(13, 14, 17, 0.05);
    padding: 25px 20px;
    box-shadow: 0 4px 16px 0px rgba(17, 34, 17, 0.05);
    margin: 0 0 25px 0;
    border-radius: 8px;
}

.TransferFilter .TopMainBox .Filter {
    display: flex;
    justify-content: space-between;
}

.TransferFilter .TopMainBox .Filter .form-group {
    margin: 0 10px;
    width: 100%;
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox {
    border: 1px solid rgba(121, 116, 126, 1);
    padding: 11px 15px;
    border-radius: 4px;
    position: relative;
    display: block;
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox.SelectBox {
    padding: 0px;
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox h6 {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 0 5px;
    background-color: #fff;
    font-size: 14px;
    font-family: 'Switzer-Medium';
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox .form-control-date,
.TransferFilter .TopMainBox .Filter .form-group .InputBox select {
    border: none;
    outline: none;
    width: 100%;
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox select {
    padding: 12px 15px 12px 40px;
    border-radius: 4px;
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox select {
    appearance: none;
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox .form-control-date:focus {
    outline: none;
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox .form-control-date {
    appearance: none;
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox .FilterIcon {
    position: absolute;
    right: 12px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
}

.TransferFilter .TopMainBox .Filter .form-group .InputBox .StaffIcon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.TransferFilter .TopMainBox .Filter .SearchBtn {
    background-color: rgba(243, 4, 44, 1);
    border-radius: 4px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 46px;
    font-size: 18px;
}

.TransferFilter .TopMainBox .CommonInputBox .form-control {
    padding: 9px 10px 9px 40px;
    height: auto;
}

.TransferFilter .TopMainBox .CommonInputBox h6 {
    background-color: #fff;
}

.TransferFilter .TopMainBox .CommonInputBox.First {
    min-width: 250px;
}



.TransferHeader .Radio {
    color: #fff;
}

.TransferHeader .Radio .checkmark {
    height: 20px;
    width: 20px;
    border-color: #fff !important;
    font-size: 18px;
    background-color: transparent !important;
}

.TransferHeader .Radio input:checked~.checkmark {
    background-color: transparent !important;
    border-color: #EC1E32 !important;
}

.TransferHeader .Radio .checkmark:after {
    height: 12px;
    width: 12px;
}


.ShowFlightsFilter .CommonInputBox .form-control {
    padding: 10px 10px 10px 40px;
}

.ShowFlightsFilter .CommonInputBox .form-control:focus {
    border-color: transparent;
}

.ShowFlightsFilter .CommonInputBox.First {
    min-width: 250px;
}

.ShowFlightsFilter .Filter .ShowHotels {
    padding: 12px 10px;
    width: 100%;
}

.EnterHotelDetails h6 {
    color: rgba(17, 34, 17, 1);
    font-size: 14px;
    font-family: 'Switzer-bold';
    margin: 0 10px 0 0;
}

.EnterHotelDetails p {
    color: rgba(17, 34, 17, 1) !important;
    font-size: 14px !important;
    font-family: 'Switzer-Regular' !important;
}



.AboutArea .section-title {
    text-align: center;
    margin: 0 0 30px 0;
}

.AboutArea .section-title h1 {
    color: rgba(3, 42, 82, 1);
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.AboutArea p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    line-height: 36px;
    margin: 0 0 15px 0;
}



.ContactFormArea {
    position: relative;
    padding: 50px 0 100px 0;
}

.ContactFormArea:before {
    content: '';
    background: #f45b6a0a;
    height: 50%;
    position: absolute;
    width: 100%;
    top: 0
}

.ContactFormArea .ContactFormHead {
    text-align: center;
    margin: 0 0 60px 0;
    position: relative
}

.ContactFormArea .ContactFormHead h3 {
    margin: 10px 0 20px 0
}

.ContactFormArea .ContactFormHead p {
    color: #636571;
    font-size: 15px;
    margin: 0 0 15px 0;
    line-height: 27px;
    font-weight: 500;
}

.ContactFormArea .ContactFormBody {
    background: #f7f7f7;
    padding: 70px;
    position: relative
}

.ContactFormArea .ContactFormBody .form-group {
    margin: 0 0 30px 0;
    position: relative;
}

.ContactFormArea .ContactFormBody.BrandDetails .form-group h6 {
    font-size: 14px;
    color: #6c757d;
    font-family: 'Switzer-medium' !important;
}

.ContactFormArea .ContactFormBody .form-group .form-control {
    border: 1px solid #E7E7E7;
    height: auto;
    font-size: 14px;
    padding: 15px 15px;
    font-weight: 500;
    box-shadow: none;
    color: #6c757d;
    border-radius: 4px;
}

.ContactFormArea .ContactFormBody .form-group .CheckBox {
    color: #000
}

.ContactFormArea .ContactFormBody .form-group h6 {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
    line-height: 28px;
}

.ContactFormArea .ContactFormBody .form-group.answer {
    display: none
}

.ContactFormArea .ContactFormBody .form-group h4 {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    font-family: '' Rubik''
}

.ContactFormArea .ContactFormBody .form-group h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter'
}

.ContactFormArea .ContactFormBody .form-group button {
    outline: 0;
    width: 100%;
    background: #f3042c;
    border: none;
    padding: 13px 0;
    color: #fff;
    font-weight: 500;
    letter-spacing: .4px;
    font-size: 14px
}

.ForgotPassword {
    color: blue;
    margin-bottom: 30px;
    display: inline-block;
}

.ForgotPassword:hover {
    color: blue;
}

.ContactFormArea .ContactFormBody .form-group span.EyesIcon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.ContactFormArea .ContactFormBody .form-group span.EyesIcon2 {
    position: absolute;
    right: 20px;
    top: 45%;
    transform: translateY(-40%);
    cursor: pointer;
}

.ContactFormArea .ContactFormBody .form-group span.EyesIcon2.New {
    top: 24px;
    right: 10px;
}

.CommonForm {
    width: 100%;
}

.CommonForm .form-group {
    margin: 0 0 20px 0;
    position: relative;
}

.CommonForm .form-group label {
    display: block;
    color: #6c757d;
    font-size: 14px;
    text-transform: capitalize;
    margin: 0 0 10px 0
}

.CommonForm .form-group .form-control {
    height: auto;
    padding: 10px 10px 10px 15px;
    border: 1px solid #b3b3b3;
    border-radius: 5px;
    box-shadow: none;
    font-size: 14px;
    display: inline-block;
}

.CommonForm .form-group .UserPlan {
    display: block;
    text-align: center;
}

.CommonForm .form-group .UserPlan li {
    display: inline-block;
    margin: 0 15px;
}

.CommonForm .form-group .Avaialable li {
    display: inline-block;
    width: 24%;
    margin: 0 0 15px 0;
}

.CommonForm .form-group .ck.ck-editor {
    margin: 0 0 20px 0;
}

.CommonForm .form-group .ck.ck-label.ck-voice-label {
    display: none;
}

.CommonForm .form-group .ck.ck-content.ck-editor__editable {
    padding: 10px;
    min-height: 275px;
}

.CommonForm .form-group .Icon {
    position: absolute;
    right: 15px;
    top: 38px;
}

.CommonForm .form-group .VehicleBox {
    background: #f9f9f9;
    padding: 20px 20px 5px 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
}

.UploadBox .Upload {
    border: 2px dashed #b9b9b9;
    padding: 37px 0;
    border-radius: 8px;
    position: relative;
    display: inline-block;
    width: 160px;
    height: 135px;
    text-align: center;
    background-color: #fff;
}

.UploadBox .Upload i {
    font-size: 24px
}

.UploadBox .Upload span {
    font-size: 14px;
    color: #6c757d;
    text-transform: capitalize;
    display: block;
}

.UploadBox .Upload input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 99999;
}

.UploadBox figure {
    margin: 0 auto 0;
    width: 170px
}

.ContactFormArea .ContactFormHead h3 {
    margin: 10px 0 20px 0;
}

.TitleBox h3 {
    font-size: 45px;
    font-weight: 600;
    color: #1F3667;
    margin: 10px 0 0 0;
    line-height: 60px;
}

.ContactFormArea .ContactFormHead p {
    color: #636571;
    font-size: 15px;
    margin: 0 0 15px 0;
    line-height: 27px;
    font-weight: 500;
}

.TitleBox span {
    border: 1px solid #EEE;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    color: #DD7513;
    font-weight: 700;
}

.ContactFormArea .ContactFormBody {
    background: #f7f7f7;
    padding: 70px;
    position: relative;
}

.UploadBox .Upload figure {
    width: 100%;
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    border-radius: 10px;
    height: calc(100% - 6px);
    bottom: 3px;

}

.UploadBox .Upload figure img {
    width: calc(100% - 6px);
    border-radius: 10px;
    height: 100%;
}

.UploadBox .Upload .CloseModal {
    position: absolute;
    top: -10px;
    right: -10px;
    background: #ddd;
    width: 20px;
    height: 20px;
    line-height: 19px;
    border-radius: 50%;
}

.text {
    display: inline-block;
    margin-bottom: 12px;
    font-size: 14px;
    color: #474646;
}

p.signIn {
    color: rgb(102, 102, 102);
    margin: 20px auto;
    text-align: center;
}

/*=====================================================================*/
.OTPBox {
    display: flex;
    justify-content: center;
    margin: 0 0 10px 0;
}

.OTPBox input {
    padding: 0;
    text-align: center !important;
    width: 60px !important;
    height: 55px;
    font-size: 22px;
    font-weight: 600;
    color: #000;
    line-height: 30px;
    margin: 10px;
}

.ContactFormBody1 {
    width: 65%;
    margin: auto;
}


.Navigation {
    transition: all 0.2s ease 0s;
    /* margin-left: 235px; */
    padding: 0px 0px 0px 3.2rem;
    /* width: calc(100% - 235px); */
    text-align: right;
    height: 60px;
    /* background-color: rgb(0, 166, 182); */
    display: flex;
    align-items: center;
    justify-content: end;
}

.Navigation .Bell {
    display: inline-block;
}

.Navigation .Bell a {
    color: rgb(255, 255, 255);
    font-size: 23px;
}

.Navigation .Avater {
    margin: 0px 0px 0px 30px;
    position: relative;
    display: inline-block;
    padding: 0px 20px 0px 0px;
}

.Navigation .Avater a {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: rgb(0, 0, 0);
    padding: 10px 0px;
    font-weight: 600;
}

.Navigation .Avater a figure {
    width: 40px;
    border-radius: 50%;
    margin: 0px 15px 0px 0px;
    overflow: hidden;
    height: 40px;
    border: 2px solid rgb(255, 255, 255);
    background-color: white;
}

.Navigation .Avater ul {
    position: absolute;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 11px 0px;
    transform: perspective(600px) rotateX(-90deg);
    transform-origin: 0px 0px 0px;
    right: 0px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease 0s;
    z-index: 99;
    top: 100%;
    text-align: left;
    min-width: 200px;
    display: block;
    width: auto;
}

.Navigation .Avater ul li {
    margin: 0px;
    border-bottom: 1px solid rgb(210, 211, 212);
}

.Navigation .Avater ul li:first-child {
    background: rgb(0, 166, 182);
    position: relative;
    padding: 15px 10px 15px 60px;
    text-align: left;
    border-bottom: 1px solid rgb(255, 255, 255);
}

.Navigation .Avater ul li figure {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 15px;
    border: 2px solid rgb(255, 255, 255);
    overflow: hidden;
    margin: 0px;
}

.Navigation .Avater ul li h4 {
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 18px;
}

.Navigation .Avater ul li h4 span {
    font-size: 11px;
    font-weight: 400;
}

.Navigation .Avater ul li a {
    color: rgb(78, 78, 78);
    padding: 10px 20px;
    font-weight: 400;
    transition: all 0.5s ease-in-out 0s;
}

.Navigation .Avater ul li a span {
    margin: 0px 10px 0px 0px;
}

.Navigation .Avater ul li a:hover {
    background-color: rgb(0, 166, 182);
    color: rgb(255, 255, 255);
}

.Navigation .Avater:hover ul {
    opacity: 1;
    visibility: visible;
    transform: perspective(600px) rotateX(0deg);
}



.BorderNew {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 15px;
    margin: 10px;
}


.CommonTabscart .nav-link {
    background-color: #ededed;
    border-radius: 5px;
    padding: 10px 25px;
    border: none;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
    margin: 0px 5px 5px 5px;
    font-size: 14px;
    display: inline-block;
}

.CommonTabscart .nav-link.active {
    border-color: #dee2e6 #dee2e6 #fff;
    background-color: #1e3f6b;
    color: #fff;
}



.FilterLeftArea .LeftCommonBox:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin: 0 0 25px 0;
}


.LeftCommonBox {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    margin: 13px 0 12px 0;
}

.LeftCommonBox button {
    width: 100%;
    background-color: transparent;
    border: none;
}

.LeftCommonBox button:focus {
    outline: none;
    border: none;
}

.LeftCommonBox .LeftCommonHeading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0 0px 0;
    cursor: pointer;
}

.LeftCommonBox .LeftCommonHeading h6 {
    color: #000;
    font-size: 16px;
    font-family: 'Switzer-Semibold';
}

.LeftCommonBox .LeftCommonHeading i {
    font-size: 18px;
    transition: all 0.5s;
}

.LeftCommonBox .LeftCommonContent {
    padding: 0 0 20px 0;
}

.LeftCommonBox .LeftCommonContent .fa-heart {
    color: rgba(236, 30, 50, 1);
    font-size: 16px;
}

.LeftCommonHeading[aria-expanded="false"] span i {
    transform: rotate(180deg);
}

.LeftCommonBox .card-body {
    padding: 0.25rem 0 0 0 !important;
    background-color: transparent !important;
    border: none !important;
}

.LeftCommonBox .LeftCommonHeading span {
    transform: rotate(180deg);
}

/* ================================================================================ */
.NewSearchBar {
    position: relative;
    min-width: 250px;
}

.NewSearchBar input {
    padding: 12px 15px;
    height: auto;
}

.NewSearchBar .NsbarOverlay {

    padding: 20px 15px;
    border: 1px solid #ddd;
    background-color: #fff;
}

.ModalClassName {
    z-index: 9999;
}

.NewSearchBar .NsbarOverlay a {
    color: #000;
}

.NewSearchBar .NsbarOverlay h4 {
    font-size: 15px;
    font-weight: 600;
}

.NewSearchBar .NsbarOverlay h6 {
    font-size: 13px;
    font-weight: 600;
}

.NewSearchBar .NsbarOverlay .PlusMinusBOx {
    display: flex;
    align-items: center;
}

.NewSearchBar .NsbarOverlay span {
    border: 1px solid #ddd;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.NewSearchBar .NsbarOverlay span i {
    font-size: 10px;
}

.NewSearchBar .NsbarOverlay .form-control {
    width: 35px;
    height: 35px;
    margin: 0 10px;
}

.NewSelectBox select {
    margin: 0px !important;
    min-width: 50px;
    padding: 2px;
}

.NewSelectBox .addition-person {
    margin: 0px 5px 0px 0px;
}

.NewSelectBox .addition-person select {
    width: auto !important;
    height: auto !important;
}

.HotelBannerArea figure a:first-child {
    display: block;
}

.HotelBannerArea figure a {
    display: none;
}

/* ============================================================================== */
.HotelBannerArea {
    padding: 35px 0;
}

.HotelBannerArea figure img {
    width: 100%;
}

.HotelBannerArea {
    display: flex;
}

.HotelBannerArea .HotelBannerAreaLeft {
    width: calc(50% - 5px);
    margin-right: 5px;
}

.HotelBannerArea .HotelBannerAreaLeft figure {
    border-radius: 25px 0 0 25px;
}

.HotelBannerArea .HotelBannerAreaRight {
    width: 50%;
}

.HotelBannerArea .HotelBannerAreaRight {
    display: flex;
}

.HotelBannerArea .HotelBannerAreaRight .HotelBannerAreaRightOne {
    width: calc(50% - 5px);
    margin: 0 5px 0 0;
}

.HotelBannerArea .HotelBannerAreaRight .HotelBannerAreaRightOne .FirstFigure {
    margin-bottom: 5px;
}

.HotelBannerArea .HotelBannerAreaRight .HotelBannerAreaRightTwo .FirstFigure {
    margin-bottom: 5px;
}

.HotelBannerArea .HotelBannerAreaRight .HotelBannerAreaRightTwo {
    width: 50%;
}

.HotelBannerArea .HotelBannerAreaRight .HotelBannerAreaRightOne figure,
.HotelBannerArea .HotelBannerAreaRight .HotelBannerAreaRightTwo figure {
    margin-bottom: 0px;
}

.HotelBannerAreaRightOne .LastFigure {
    position: relative;
}

.HotelBannerAreaRightOne .LastFigure .ShowAllphotosBtn {
    position: absolute;
    color: #000;
    font-weight: 500;
    font-family: 'Switzer-Medium';
    border-radius: 8px;
    border: 1px solid #000;
    padding: 8px 15px;
    background-color: #fff;
    bottom: 25px;
    right: 25px;
    display: flex;
    align-items: center;
}

.HotelBannerAreaRightOne .LastFigure .ShowAllphotosBtn img {
    width: 20px;
    margin: 0 8px 0 0;
}

.HotelNameInformation {
    display: flex;
    justify-content: space-between;
}


/* .modalBoxNew2 .modal-dialog{max-width: 950px}; */

.modal-dialog-2 {
    position: absolute;
    top: 100px;
}

.backGroundColor a {
    font-size: 14px;
    color: black;
}

.backGroundColor a:hover {
    font-size: 14px;
    color: black;
}

/* .backGroundColor .carousel-control-prev-icon img{color: black;background: #ddd;} */

.NewSearchBar {}

.NewSearchBar .form-control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: black;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #79747e;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.NewSearchBar .form-control:focus {
    box-shadow: none;
}

.NewSearchBar .NsbarOverlay a span {
    display: inline-block;
    margin: 0px 10px 0px 0px;
    text-align: center;
    line-height: 15px;
}

.NewSearchBar .NsbarOverlay button {
    display: block;
    background: #032a52;
    color: white;
    font-size: 12px;
    border: 1px solid transparent;
    border-radius: 8px;
    width: 50%;
    padding: 4px 10px;
    margin-top: 10px;
}



.TransferBox {
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
}

.TransferBox .TransferBody {
    padding: 35px 35px 25px;
}

.HotelDropdownMain {
    position: relative;
}

.HotelDropdownOverlay {
    position: absolute;
    border: 1px solid #ddd;
    background-color: #fff;
    min-width: 500px;
    border-radius: 5px;
    padding: 20px 15px;
    z-index: 999;
}

.HotelDropdownBox {
    margin: 0 0 15px 0;
}

.HotelDropdownBox h2 {
    font-size: 20px;
}

.HotelDropdownBox p {
    font-size: 16px;
    margin: 0 0 10px 0;
}

.SendQueryFilter {
    padding: 50px 75px 0px;
}

.SendQueryFilter .section-title {
    text-align: center;
    margin: 0 0 50px 0;
}

.SendQueryFilter .section-title h2 {
    font-size: 28px;
    font-weight: 700;
}

.SendQueryFilter .form-group h6 {
    margin: 0 0 10px 0;
    color: rgba(102, 112, 133, 1);
    font-size: 15px;
}

.SendQueryFilter .form-group .form-control:focus {
    box-shadow: none;
}

.SendQueryFilter ::placeholder {
    font-size: 15px;
}

.SendQueryFilter .form-group select {
    font-weight: 400;
    font-size: 15px;
    color: rgba(102, 112, 133, 1)
}

.SendQueryFilter .form-group select option {
    font-weight: 400;
    font-size: 15px;
    color: rgba(102, 112, 133, 1)
}

.SendQueryFilter .ck-content {
    min-height: 200px;
}

.SubmitBtn {
    background-color: rgba(243, 4, 44, 1);
    border: none;
    padding: 10px 25px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
    margin: 0px auto;
    display: block;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}



.radiusOfService .rc-slider-handle:before {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    left: -3px;
    position: absolute;
    top: 15px;
}

.rc-slider-handle:before {
    content: attr(aria-valuenow) "";
}

.BrandDetailsLogo {
    width: 200px;
    position: relative;
}

.BrandDetailsLogo span {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.05);
}

.BrandDetailsLogo span input {
    opacity: 0;
    z-index: 99;
}

.BrandDetailsLogo span input i {
    position: absolute;
}

.ShareThisArea {
    padding: 75px 100px
}

.ShareDealsBox p {
    font-size: 14px;
    color: #000;
    margin-top: 20px;
}

.NewTitleHeading h3 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

.MartinBoxMain h1 {
    font-size: 24px;
    font-weight: 600;
}

.MartinBoxMain {
    margin: 0 0 25px 0;
}

.MarketingArea {
    padding: 75px 100px;
}

.MarketingArea .MarketingBox {
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
}

.MarketingArea .MarketingBox figure {
    border-radius: 10px;
    margin-bottom: 0.5rem !important;
}

.MarketingArea .MarketingBox figure img {
    width: 100%;
}

.MarketingArea .MarketingBox figcaption {
    padding: 0px 10px 10px;
}

.MarketingArea .MarketingBox figcaption h4 {
    font-size: 16px;
    font-weight: 500;
}

.MarketingDetailsArea {
    padding: 75px 100px;
    background-color: #f3f3f3;
}

.MarketingBanner {
    background-image: url('../images/newBannerimage.jpg');
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 100px 0px;
}

/* .MarketingBanner {background-color: orange; padding: 50px;} */
.MarketingBanner .MarketingBannerContent {
    width: 35%;
    margin: 0px auto;
}

.MarketingBanner .MarketingBannerContent h2 {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
}

.MarketingBanner .MarketingBannerContent p {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
}

.MarketingBanner .MarketingBannerContent .SearchBar {
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0px 5px;
    border-radius: 30px;
    height: 50px;
}

.MarketingBanner .MarketingBannerContent .SearchBar input {
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 30px;
    border-radius: 30px;
    border: none;
    font-size: 15px;
}

.MarketingBanner .MarketingBannerContent .SearchBar input:focus {
    border: none;
    outline: none;
}

.MarketingBanner .MarketingBannerContent .SearchBar i {
    font-size: 16px;
    color: grey;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.MarketingBanner .MarketingBannerContent .SearchBar button {
    background-color: blue;
    color: #fff;
    font-size: 14px;
    text-align: center;
    position: absolute;
    right: 5px;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    padding: 5px 30px;
    border-radius: 30px;
    border: none;
    height: 90%;
}

.slider-container {
    background-color: #000;
}

.slick-slide img {
    width: 400px;
    height: 400px;
    margin: 0px auto;
}

.slick-dots {
    position: absolute;
    bottom: -50px !important;
    display: inline-flex !important;
}

.slick-dots li {
    width: 100px !important;
}

.rc-slider-handle {
    width: 20px !important;
    height: 20px !important;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .headerindex {background-color: transparent!important;}
.headerindex .nav-item .nav-link {color: #fff;} */


.MembersSlide {
    position: relative;
    height: 90px;
    min-width: 100%;
    width: 100%;
    display: inline-block;
    content: "";
    z-index: 0;
    overflow: hidden;
}


.MembersSlide:after {
    display: inline-block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    width: 2020px;
    background-image: url('../images/Members.png');
    /* -webkit-background-size: 2020px 90px;
    -moz-background-size: 2020px 90px;
    -o-background-size: 2020px 90px;
    background-size: 2020px 90px;
    -webkit-animation: NewSlideshow 50s linear infinite;
    -moz-animation: NewSlideshow 50s linear infinite;
    animation: NewSlideshow 50s linear infinite */
}



@-webkit-keyframes NewSlideshow {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: -4040px;
        -moz-background-position: -4040px;
        -o-background-position: -4040px;
        background-position: -4040px;
    }
}

@-moz-keyframes NewSlideshow {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: -4040px;
        -moz-background-position: -4040px;
        -o-background-position: -4040px;
        background-position: -4040px;
    }
}

@-o-keyframes NewSlideshow {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: -4040px;
        -moz-background-position: -4040px;
        -o-background-position: -4040px;
        background-position: -4040px;
    }
}

@keyframes NewSlideshow {
    form {
        -webkit-background-position: 0;
        -moz-background-position: 0;
        -o-background-position: 0;
        background-position: 0;
    }

    to {
        -webkit-background-position: -4040px;
        -moz-background-position: -4040px;
        -o-background-position: -4040px;
        background-position: -4040px;
    }
}

.ApplyBtn {
    display: inline-block;
    background-color: rgba(236, 30, 50, 1);
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff !important;
}

.ApplyBtn img {
    margin: 0 5px 0 0;
}



.AvailableRooms {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin: 0 0 20px 0;
}

.AvailableRooms .AvailableRoomsHead {
    display: flex;
    margin-bottom: 15px;
}

.AvailableRooms .AvailableRoomsHead figure {
    margin: 0;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
}

.AvailableRooms .AvailableRoomsHead figcaption {
    width: calc(100% - 50px);
    padding: 0 0 0 15px;
}

.AvailableRooms .AvailableRoomsHead figcaption h5 {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.AvailableRooms .AvailableRoomsHead figcaption ul {
    margin: 0;
}

.AvailableRooms .AvailableRoomsHead figcaption ul li {
    display: inline-block;
    margin: 0 10px 0 0;
}

.AvailableRooms .AvailableRoomsHead figcaption ul li span {}


.AvailableRooms .AvailableRoomsBody {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px 0;
    margin: 0 0 15px 0;
}

.AvailableRooms .AvailableRoomsBody h5 {
    margin: 0;
    font-size: 15px;
}

.AvailableRooms .AvailableRoomsBody .AddToCartBtn {
    font-size: 14px;
    border: none;
    padding: 10px 20px;
}

.AvailableRooms .AvailableRoomsBody:last-child {
    border: none;
    margin: 0;
    padding: 0;
}










.gallery-Images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 520px;
    overflow: scroll;
}

.gallery-Images figure {
    width: 180px;
}

.ShowAllPhotos {
    position: relative;
}

.ShowAllPhotos figcaption {
    position: absolute;
    left: 50%;
    top: 50%;
    color: black(255, 255, 255, 1);
    font-size: 16px;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 10px 5px;
}

.ModalBox .modal-dialog {
    max-width: 450px;
    margin: 50px auto
}

.ModalBox .modal-dialog .CloseModal {
    position: absolute;
    top: -13px;
    right: -13px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border: 2px solid rgba(0, 0, 0, .5);
    text-align: center;
    color: #000;
    font-size: 15px;
    border-radius: 50%
}

.ModalBox .modal-dialog .Decline {
    margin: 0
}

.ModalBox .modal-dialog .Decline h3 {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 22px;
    margin: 0 0 5px;
    text-align: left
}

.ModalBox .modal-dialog .Decline p {
    font-size: 16px;
    font-family: "Roboto";
    color: #777;
    text-align: left
}

.ModalBox .modal-dialog .Decline h4 {
    margin: 15px 0 0 0;
    text-align: right
}

.ModalBox .modal-dialog .Decline h4 a {
    text-transform: capitalize;
    margin: 0 0 0 30px;
    font-size: 17px;
    color: #49a0d5;
    font-weight: 400;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out
}

.ModalBox .modal-dialog .Decline h4 a:hover {
    color: #000
}

.ModalBox .modal-dialog .Category {
    padding: 10px;
}

.ModalBox .modal-dialog .Category h3 {
    text-align: center;
    font-weight: 800;
    margin: 0 0 25px 0;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px;
    font-size: 23px
}

.ModalBox .modal-dialog .Category h3 span {
    display: block;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
}

.ModalBox .modal-dialog .Category .form-group {
    margin: 0 0 35px;
    position: relative
}

.ModalBox .modal-dialog .Category .form-group span.error-Number {
    position: absolute;
    z-index: 9;
    color: red;
    bottom: -22px;
}

.ModalBox .modal-dialog .Category .form-group label {
    display: inline-block;
    text-transform: capitalize;
    font-weight: 500;
    color: #000;
    font-size: 13px;
    background-color: #fff;
    margin: 0 0 -10px 0;
    padding: 6px 10px;
    position: absolute;
    top: -17px;
    left: 10px;
    border-radius: 5px;
    z-index: 1;
    font-family: "Roboto";
}

.ModalBox .modal-dialog .Category .form-group .form-control {
    box-shadow: none;
    border: 1px solid #c3c3c3;
    height: auto;
    font-size: 14px;
    color: #000;
    line-height: 22px;
    font-family: "Roboto";
    padding: 15px 15px 10px;
}

.ModalBox .modal-dialog .Category .form-group textarea.form-control {
    height: auto
}



.CountryPackage .CountryPackageRight {}

.CountryPackage .CountryPackageRight ul {}

.CountryPackage .CountryPackageRight ul li {}

.CountryPackage .CountryPackageRight ul li span {
    display: block;
    border: 2px solid #fff;
    box-shadow: 0px 0px 10px #c3bcbc;
    border-radius: 10px;
    overflow: hidden;
}

.CountryPackage .CountryPackageLeft .editor {
    padding: 0 0 0 10px;
}

.CountryPackage .CountryPackageLeft .editor h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
}

.CountryPackage .CountryPackageLeft .editor h4 {
    font-size: 15px;
    font-weight: 700;
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
}

.CountryPackage .CountryPackageLeft .editor p {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
    color: #676666;
}

.CountryPackage .CountryPackageLeft .editor ul {
    padding: 0 0 0 20px;
}


.CountryPackage .CountryPackageLeft .editor ul li {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 10px 0;
    letter-spacing: 0.5px;
    color: #676666;
    list-style: disc;
}

.CountryPackageName {}

.CountryPackageName h1 {
    font-size: 25px;
    color: #000;
    margin: 0 0 15px 0;
    font-family: 'Switzer-bold';
    letter-spacing: 0.9px;
}

.CountryPackageName h3 {
    font-size: 16px;
    margin: 0 0 15px 0;
}

.CountryPackageName h3 .Icon {
    display: inline-block;
    width: 13px;
    margin: 0 6px 0 0;
}

.CountryPackageName h3 strong {
    font-weight: 700;
    margin: 0 5px 0 0;
}

.CountryPackageName h3 .Package {
    color: #7b7777;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.4px;
}

.OverviewDetails {}

.OverviewDetails p {
    font-size: 15px;
    line-height: 30px;
    color: #777;
    margin: 0 0 10px 0;
}


.MealsBoxMain {
    margin: 10px 0 0 10px;
}

.MealsBoxMain .MealsBox {
    min-width: 140px;
    border-radius: 10px;
    border: 2px dashed #c1bcbc;
    padding: 17px 10px;
    margin: 4px;
}

.MealsBoxMain .MealsBox span {}

.MealsBoxMain .MealsBox h4 {
    margin: 10px 0 0 0;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #777272;
}

.MapBox {
    border: 2px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px #ddd;
}


.rc-slider-horizontal .rc-slider-rail {
    background-color: #c7c5c5;
}


.rc-slider-horizontal .rc-slider-track {
    background-color: rgba(243, 4, 44, 1);
}

.rc-slider-horizontal .rc-slider-handle {
    width: auto !important;
    height: auto !important;
    bottom: -26px;
    padding: 0 6px;
    border: 1px solid #a7a7a7;
    border-radius: 5px !important;
    background-color: #ddd;
}

.tab-content {
    min-height: 100px;
}

/* .rc-slider-horizontal .rc-slider-handle:before{} */
.NewCardStyle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 0 15px 0;
    background-color: #f3f3f3;
    padding: 10px;
    border-radius: 10px;
}

.NewCardStyle h6 {
    margin-bottom: 0px;
}

/* .NewCardStyle ul li {width: calc(100%/6 - 10px); margin: 0 5px;} */
.NewCardStyle .Occupancy {
    color: #000;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.NewCardStyle .Occupancy span {
    margin: 5px 0 0 0;
    display: inline-block;
}

.NewCardStyle h2.Purple {
    color: rgb(148, 11, 146);
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.NewCardStyle .Purple .LightBlue {
    color: blue;
    font-size: 13px;
}

.NewCardStyle h2.Blue {
    color: rgb(5, 12, 156);
    font-weight: bold;
    font-size: 20px;
    text-align: center;
}

.NewCardStyle .Blue .Green {
    color: green;
    font-size: 13px;
}

.NewCardStyle button {
    background: rgb(76, 175, 80);
    color: #000;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #000;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
}

.NewCardStyle button:focus {
    outline: none;
}

.NewCardStyle button i {
    margin-right: 8px;
}



.NewTablePrice thead tr:first-child {
    border-top: 0.5px solid rgba(17, 34, 17, 0.25);
}

.NewTablePrice thead tr th {
    color: rgba(17, 34, 17, 1);
    padding: 10px 0 10px 0;
    font-size: 20px;
    font-family: 'Switzer-bold';
}

.NewTablePrice tbody tr td {
    color: rgba(17, 34, 17, 1);
    padding: 10px 0 10px 0;
    font-size: 15px;
    font-family: 'Switzer-Medium';
}

.NewTablePrice tbody tr td:last-child {
    color: rgba(17, 34, 17, 1);
    padding: 10px 0 5px 0;
    font-size: 15px;
    font-family: 'Switzer-Semibold';
    text-align: right;
}

.NewTablePrice tfoot tr td {
    color: rgba(17, 34, 17, 1);
    padding: 10px 0 10px 0;
    font-size: 20px;
    font-family: 'Switzer-Medium';
}

.NewTablePrice tfoot tr {
    border: 0.5px solid rgba(17, 34, 17, 0.25);
    border-left: none;
    border-right: none;
}

.NewTablePrice tfoot tr td:last-child {
    color: rgba(17, 34, 17, 1);
    padding: 5px 0 5px 0;
    font-size: 16px;
    font-family: 'Switzer-Semibold';
    text-align: right;

}

/* login box */
.LoginArea .LoginBox {
    background-color: #fff;
    padding: 50px;
    box-shadow: 0 0 10px #858585;
    border-radius: 10px;
    width: 469px;
    position: relative;
}

.LoginArea .LoginBox figure {
    text-align: center;
    margin: auto !important;
    background-color: #fff;
    width: 100%;
    padding: 20px 0px;
}

.LoginArea .LoginBox form {
    margin: auto
}

.LoginArea .LoginBox form h3 {
    font-size: 23px;
    margin: 0 0 20px 0;
    font-weight: 800;
    color: #000;
    text-align: center;
}

.LoginArea .LoginBox form p {
    font-size: 13px;
    margin: 0 0 20px 0;
    font-weight: 600;
    text-align: center
}

.LoginArea .LoginBox form .form-group {
    margin: 0 0 20px 0;
    position: relative;
}

.LoginArea .LoginBox form .form-group label {
    display: block;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
}

.LoginArea .LoginBox form .form-group label a {
    float: right
}

.LoginArea .LoginBox form .form-group .form-control {
    box-shadow: none;
    border: 1px solid #ddd;
    padding: 10px 15px;
    height: auto;
    border-radius: 5px;
    font-size: 15px;
    color: #000
}

.LoginArea .LoginBox form .form-group .form-control:focus {
    border-color: #d2c5e0
}

.LoginArea .LoginBox form .form-group .Icon {
    position: absolute;
    top: 42px;
    right: 15px;
}

.LoginArea .LoginBox form .form-group .Checkboxs {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.LoginArea .LoginBox form .form-group .Checkboxs a {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 15px
}

.LoginArea .LoginBox form .form-group .OTPBox {
    display: flex;
    justify-content: center;
    margin: 0 0 10px 0;
}

.LoginArea .LoginBox form .form-group .OTPBox .form-control {
    padding: 0;
    text-align: center;
    width: 60px;
    height: 55px;
    font-size: 22px;
    font-weight: 600;
    color: #000;
    line-height: 30px
}

.LoginArea .LoginBox form .Button {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.LoginArea .LoginBox h6 {
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
    font-weight: 600;
    color: #000
}

.LoginArea .LoginBox h6 a {
    color: #d2c5e0;
    font-weight: 700
}

.LoginArea .LoginBox .form-group-checkBox {
    display: flex;
    justify-content: space-between;
}

.LoginArea .LoginBox .check {
    display: flex;
    align-items: baseline;
}

.LoginArea .LoginBox form .Button {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.NewButton {
    border: none;
    padding: 10px 30px;
    text-align: center;
    transition: 0.5s all ease-in-out;
    font-weight: 600;
    display: inline-block;
    position: relative;
    background-color: #F3042C;
    font-size: 14px;
    color: #fff;
    text-transform: capitalize;
    border-radius: 5px;
    outline: 0 !important;
    box-shadow: none;
    display: inline-block;
}

.owl-item {
    /* width: 119px !important;
    margin: 10px !important; */
    float: left;
}


/*          Wallet         */
.WalletCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 50px 0;
    padding: 0 30px;
    background-color: rgba(243, 4, 44, 1);
    border-radius: 20px;
    min-height: 150px;
}

.WalletCard aside h2 {
    color: #fff;
    margin: 0 0 15px 0;
    font-family: 'Switzer-Semibold';
    font-size: 20px;
}

.WalletCard aside p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    font-family: 'Switzer-Regular';
}

.WalletCard .AddMoneyBtn {
    background-color: #fff;
    outline: none;
    border-radius: 4px;
    color: #fff;
    font-family: 'Switzer-Medium';
    text-align: center;
    font-size: 14px;
    padding: 10px 30px;
    display: inline-block;
    color: rgba(243, 4, 44, 1);
}

.AddMoneyBtnNew {
    color: #fff;
    outline: none;
    border-radius: 4px;
    color: #fff;
    font-family: 'Switzer-Medium';
    text-align: center;
    font-size: 14px;
    padding: 10px 30px;
    display: inline-block;
    background-color: rgba(243, 4, 44, 1);
    border: none;
}


.TableHeader h3 {
    color: rgba(0, 0, 0, 1);
    margin: 0 0 15px 0;
    font-family: 'Switzer-Semibold';
    font-size: 20px;
}

ul.TransactionTable li {
    margin: 0 0 30px 0;
}

ul.TransactionTable li:last-child {
    margin-bottom: 0px;
}

ul.TransactionTable li aside {
    display: flex;
    justify-content: space-between;
}

ul.TransactionTable li aside:first-child {
    margin: 0 0 10px 0;
}

ul li aside h2 {
    color: rgba(102, 112, 133, 1);
    font-size: 18px;
    font-family: 'Switzer-Medium';
}

ul.TransactionTable li aside h5 {
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    font-family: 'Switzer-Medium';
}

ul.TransactionTable li aside p {
    color: rgba(102, 112, 133, 1);
    font-size: 12px;
    font-family: 'Switzer-Regular';
}

.ModalBox.ModalBoxAdd .modal-dialog {
    max-width: 450px
}

.ModalBox.ModalBoxAdd .modal-dialog .modal-content {
    background: #fff !important;
    border-radius: .3rem !important;
    border: 1px solid rgba(0, 0, 0, .2) !important;
    /* padding: 40px !important; */
}

.ModalBox.ModalBoxAdd .form-group {
    margin: 0 0 20px !important;
}

.PaymentStatusArea {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.PaymentStatusArea .PaymentStatusBox {
    background-color: #fff;
    width: 425px;
    height: 325px;
    margin: 0px auto 20px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.1);
}

.PaymentStatusArea .PaymentStatusBox h3 {
    font-size: 24px;
    color: #000;
    font-weight: 700;
}

.PaymentStatusArea .PaymentStatusBox span {
    display: inline-block;
    line-height: 49px;
    width: 49px;
    margin: 15px 0;
    height: 49px;
    border-radius: 50%;
    font-size: 18px;
}

.PaymentStatusArea .PaymentStatusBox span.GreenBorder {
    border: 1px solid green;
}

.PaymentStatusArea .PaymentStatusBox span.RedBorder {
    border: 1px solid red;
}

.PaymentStatusArea .PaymentStatusBox span .fa-check {
    color: green;
}

.PaymentStatusArea .PaymentStatusBox span .fa-xmark {
    color: red;
}

.PaymentStatusArea .PaymentStatusBox h6 {
    color: red;
    font-size: 14px;
    margin: 0 0 20px 0;
}

.PaymentStatusArea .PaymentStatusBox p {
    font-size: 16px;
    color: #000;
    margin: 0 0 10px 0;
}


.PopularDestination {
    padding: 75px 100px 25px 100px;
    background-color: #F1F5F9;
    margin-top: -6px;
}

.PopularDestination .PopularBox {
    position: relative;
    margin: 0 0 50px 0;
}

.PopularDestination .PopularBox img {
    border-radius: 5px;
}

.PopularDestination .PopularBox span {
    position: absolute;
    background-color: #fff;
    border-radius: 3px;
    left: 10px;
    top: 20px;
    padding: 5px;
    text-transform: uppercase;
    color: #EC1E32;
    font-family: 'Switzer-Bold';
    letter-spacing: 0.5px;
}

.PopularDestination .PopularBox h2 {
    position: absolute;
    left: 20px;
    bottom: 50px;
    color: #fff;
    font-family: 'Switzer-Bold';
    letter-spacing: 0.5px;
    font-size: 22px;
}

.PopularDestination .PopularBox::before {
    content: "";
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 31%, rgba(0, 0, 0, 0.2) 53.58%, rgba(0, 0, 0, 0.588542) 81.04%, #000000 100%);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.PopularDestination .section-title {
    text-align: center;
    margin: 0 0 75px 0;
}

.PopularDestination .section-title h1 {
    color: rgba(0, 0, 0, 1);
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.PopularDestination .section-title span {
    color: rgba(243, 4, 44, 1);
}

.PopularDestination .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.OptionalsBoxOuter {
    padding-bottom: 15px;
}

.OptionalsBoxOuter .Description {
    font-size: 14px;
    margin: -5px 10px 0;
}


.TeamArea {
    padding: 50px 75px 0px;
}

.TeamArea .section-title {
    padding-bottom: 50px;
    margin-bottom: 0px;
}

.TeamArea .section-title {
    text-align: center;
    margin: 0 0 50px 0;
}

.TeamArea .section-title h1 {
    color: rgba(3, 42, 82, 1);
    letter-spacing: -0.7px;
    font-family: 'Switzer-Semibold';
    font-size: 48px;
    margin: 0 0 20px 0;
}

.TeamArea .section-title span {
    color: rgba(243, 4, 44, 1)
}

.TeamArea .section-title p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    font-family: 'Switzer-Regular';
}

.TeamArea .myTeamArea {
    margin: 0 0 50px 0;
}

.TeamArea .myTeamArea figure {
    border-radius: 50%;
    margin: 0px auto 25px;
    overflow: hidden;
    border: 4px solid #f9f9f9;
    box-shadow: 0 15px 35px -10px rgba(0, 0, 0, 0.2);
    width: 200px;
}

.TeamArea .myTeamArea figcaption {
    text-align: center;
}

.TeamArea .myTeamArea figcaption h4 {
    font-size: 18px;
    letter-spacing: 0.5px;
    font-family: 'Switzer-Bold';
    color: rgba(3, 42, 82, 1);
    margin: 0 0 10px 0;
}

.TeamArea .myTeamArea figcaption p {
    font-size: 15px;
    letter-spacing: 0.5px;
    font-family: 'Switzer-Regular';
    font-style: italic;
    color: rgba(102, 112, 133, 1);
    margin: 0 0 10px 0;
}

.TeamArea .myTeamArea figcaption h6 {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-family: 'Switzer-Regular';
    color: rgba(15, 23, 42, 1);
}

.ParentCompanyArea {
    padding: 100px 100px 25px;
}

.ParentCompanyArea figure {
    margin: 0;
    height: 100%;
}

.ParentCompanyArea .leftImg {
    border-radius: 15px;
    width: 100%;
    height: 100%;
}

.ParentCompanyArea .Outer {
    display: flex;
    margin: 0 0 75px 0;
}

.ParentCompanyArea .Content {
    background-color: rgba(3, 42, 82, 1);
    padding: 30px;
    border-radius: 10px;
    height: 100%;
}

.ParentCompanyArea .Content h3 {
    font-weight: 700;
    font-size: 26px;
    color: #fff;
    margin: 0 0 25px 0;
}

.ParentCompanyArea .Content p {
    border-radius: 10px;
    color: #fff;
    line-height: 28px;
    letter-spacing: 0.5px;
    font-size: 16px;
}

.industryAccreditionArea {
    padding: 100px 100px;
    background-color: #fff;
}

.industryAccreditionArea .RightImg {
    border-radius: 10px;
    overflow: hidden;
    margin-left: 25px;
}

.industryAccreditionArea .rightContent {
    text-align: center;
    margin-bottom: 50px;
}

.industryAccreditionArea .rightContent p {
    color: rgba(102, 112, 133, 1);
    font-size: 16px;
    line-height: 30px;
    margin: 0 0 15px 0;
    padding-right: 25px;
}

.awardRecognitionNew {
    background-color: #fff;
    padding: 0 100px 50px;
}

.awardRecognitionNew .section-title {
    text-align: center;
    margin: 0 0 75px 0;
}

.awardRecognitionNew h5 {
    font-family: 'Switzer-semibold';
    color: #000;
    font-size: 24px;
    margin: 0 0 15px 0;
}

.awardRecognitionNew p {
    font-size: 18px;
    font-family: 'Switzer-Regular';
    color: rgba(102, 112, 133, 1);
}

.awardRecognitionNew .awardRecognitionBox h6 {
    font-family: 'Switzer-semibold';
    color: #000;
    font-size: 20px;
    margin: 0 0 10px 0;
}

.awardRecognitionNew .awardRecognitionBox p {
    font-size: 16px;
    font-family: 'Switzer-Regular';
    color: rgba(102, 112, 133, 1);
    /* min-height: 50px; */
}

.awardRecognitionNew .awardRecognitionBox .Last {
    margin: 50px 0 0 0;
}

.awardRecognitionNew .awardRecognitionBox img {
    width: 150px;
    height: 131px;
    margin: 0px auto;
    display: block;
}

.awardRecognitionNew .awardRecognitionBox {
    display: flex;
}

.awardRecognitionNew .awardRecognitionBox aside {
    margin: 0 0 0 25px;
    padding-top: 10px;
}